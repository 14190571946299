import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { wellnessReportUrl } from "../../utils/constants";
import useApis from "../../services/useAPI";
import ReportDownloadSection from "./ReportDownloadSection";
import { format_date, track } from "../../utils/utils";
import ReportAccordion from "../../stories/Accordion/Accordion";
import { Spinner } from "react-bootstrap";

// Function to fix a known typo in exclusions name
const fix_exclusions_name = name =>
  name === "Red Meat excluding Port" ? "Red Meat excluding Pork" : name;

/**
 * The WellnessReportPage component renders the wellness report details,
 * including health areas, goals, exclusions, and download options.
 */

const WellnessReportPage = () => {
  const [reports, setReports] = useState([]);
  const { callApi, resultGet, isLoading } = useApis();
  const navigate = useNavigate();
  const location = useLocation();

  const fullName = localStorage.getItem("username");
  // Extracts only the first name
  const userName = fullName ? fullName.split(" ")[0] : "";
  const { id } = useParams();

  // Fetches wellness reports data from the API
  useEffect(() => {
    if (id) {
      callApi(null, wellnessReportUrl(id), "GET");
    }
  }, [id]);

  // Updates reports state when API result changes
  useEffect(() => {
    if (resultGet) {
      setReports([resultGet]);
    }
  }, [resultGet]);

  const params = new URLSearchParams(location.search);
  const goal_filter = params.get("goal");

  // Function to apply filter based on user selection
  const applyFilter = (filter, new_value) => {
    const old_value = params.get(filter);
    if (new_value === old_value) {
      params.delete(filter);
    } else {
      track(`report-filter-${filter}`, new_value);
      params.set(filter, new_value);
    }
    navigate(`?${params.toString()}`);
  };

  // Memoizes accordion items to prevent re-calculating on every render
  const memoizedReports = useMemo(
    () =>
      reports?.map(report => {
        const accordionItems = report.health_areas.map(area => ({
          name: area.name,
          tag: area.tag,
          personalised_description: area.personalised_description,
          description: area.description,
          effect: area.effect,
          value: area.value,
          lead: area.lead,
          advice: area.advice,
          advisements: report.advice,
          snps: report.snps.filter(item => area.snps.includes(item.snp)),
          further_research_required: report.further_research_required,
          missing: area.missing
        }));
        return {
          ...report,
          accordionItems
        };
      }),
    [reports]
  );

  if (isLoading){
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );
  }


  return (
    <div className="mb-4">
      <div className="container-fluid wellness-report-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="dashboard">

              {isLoading ? (
                <div className="text-center">
                  <Spinner animation="border" variant="info" />
                </div>
              )
                : memoizedReports.length > 0 ? (
                  memoizedReports.map(report => (
                    <div className="mb-1 w-100 col" key={report.id}>
                      <div className="row">
                        <div className="col">
                          <h1 className="text-left">{report.name}</h1>
                        </div>
                        <div className="ml-2 col-auto">
                          <ReportDownloadSection />
                        </div>
                      </div>
                      <div>
                        <p className="mb-1 small text-muted">
                          <b>Date first generated: </b>
                          {format_date(report.created)}
                        </p>
                        <p className="mb-1 small text-muted mb-3">
                          <b>Date last updated: </b>
                          {format_date(report.timestamp)}
                        </p>
                      </div>
                      <div className="row">
                        <div xs={7} md={true} className="col">
                          <h2 className="text-left">
                            <b>Health areas</b>
                          </h2>
                        </div>
                        {(report.goals?.length) || (report.exclusions?.length) ? (
                          <div xs="auto" className="col-md-5">
                            {report.goals?.length > 0 && (
                              <div className="d-flex flex-row align-items-baseline flex-wrap">
                                <p className="m-0">Goals:</p>
                                {report.goals.map(g => (
                                  <span
                                    key={g.tag}
                                    className={`small-pill clickable ${goal_filter === g.tag ? " filled" : ""
                                      }`}
                                    onClick={() => applyFilter("goal", g.tag)}
                                  >
                                    {g.name}
                                  </span>
                                ))}
                              </div>
                            )}
                            {report.exclusions?.length > 0 && (
                              <div className="d-flex flex-row align-items-baseline flex-wrap">
                                <p className="m-0">I don't eat/drink:</p>
                                {report.exclusions.map(exclusion => (
                                  <span
                                    key={exclusion.tag}
                                    className="small-pill"
                                  >
                                    {fix_exclusions_name(exclusion.name)}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div className="pt-3 pb-1">
                        <ReportAccordion
                          items={report.accordionItems}
                          userName={userName}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <span>No reports data available</span>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WellnessReportPage;
