/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { LinkCard } from "../../stories/cards/LinkCard";
import { Spinner, Alert } from "react-bootstrap";
import { Button } from "../../stories/Buttons/Button";
import ReactMarkdown from "react-markdown";
import TimelineItem from "../TestDetails/TimelineItem";
import WellnessTimeline from "../TestDetails/WellnessTimeline";
import Test from "../../assets/icons/Platform/Buy_tests.svg";
import Pink from "../../assets/icons/pink_marketing.svg";
import useApis from "../../services/useAPI";
import AppointmentDetails from "./AppointmentDetails";
import { AssessmentMessage } from "../Health Assessment/AssesmentMessage";
import ReportHighlightSection from "../WellnessReports/ReportHighlightsSection";
import ActivateKit from "../../assets/icons/Timeline/Desktop/Icons/4CollectSample.svg";
import Clipboard from "../../assets/icons/Clipboard.svg";
import KitDispatched from "../../assets/icons/Timeline/Desktop/Icons/3KitDispatched.svg";
import LabAnalysis from "../../assets/icons/Timeline/Desktop/Icons/6SampleReceived.svg";
import Doctor from "../../assets/icons/Doctor.svg";
import PhoneConsultation from "../../assets/icons/Phone_Consultation_icon.svg";
import Results from "../../assets/icons/Platform/Results.svg";
import {
  logoutUrl,
  myResultUrl,
  wellnessReportUrl
} from "../../utils/constants";
import axios from "axios";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { toSentenceCase } from "../../utils/utils";

export const statuses = [
  "error", // Other error - contact customer care
  "rejected", // Other error - contact customer care
  "created", // Not yet linked to a user
  "activated", // The user has activated with the basic info needed for the rec-engine (Also display as awaiting sample return)
  "processing", // The lab has received the samples (Sample Taken and Returned, or Sample received at Lab, in the lab, or results pending)
  "report-in-review", // In review by the doctor (Name may change depending on Matt's implantation) With Doctor
  "report-ready" // Reviewed by the doctor and ready to view
];

// Order of reasons is important
export const errorReasons = Object.freeze([
  "missing-assessment",
  "marked-rejected",
  "sample-not-received",
  "lab-delay",
  "unexpected-results",
  "missing-assessment-hormones",
  "untestable",
  "inhibitory",
  "gp-delay"
]);

export const steps = [
  {
    label: "Test Activated",
    isVisible: unconfirmed_result => {
      return true;
    },
    inProgress: status => status === "created",
    isCompleted: (status, errorReason) => {
      const activeIndex = statuses.findIndex(s => s === status);
      const stepIndex = statuses.findIndex(s => s === "activated");
      return activeIndex >= stepIndex || !!errorReason;
    },
    graphic: ActivateKit
  },
  {
    label: "Assessment Completed",
    label_before: "Complete Assessment",
    name: "assessment",
    isVisible: () => true,
    inProgress: status => status === "processing",
    isCompleted: (status, errorReason) => {
      const activeIndex = statuses.findIndex(s => s === status);
      const stepIndex = statuses.findIndex(s => s === "processing");
      const activeErrorIndex = errorReasons.findIndex(s => s === errorReason);
      const errorIndex = errorReasons.findIndex(
        s => s === "missing-assessment" || s === "missing-assessment-hormones"
      );

      return activeIndex > stepIndex || activeErrorIndex > errorIndex; //&& status !== 'error'
    },
    failed: (_status, errorReason) =>
      errorReason === "missing-assessment" ||
      errorReason === "missing-assessment-hormones",
    graphic: Clipboard,
    link: "/assessment/{test_id}/1"
  },
  {
    label: "Sample Taken & Returned",
    isVisible: unconfirmed_result => {
      return true;
    },
    inProgress: status => status === "activated",
    isCompleted: (status, errorReason) => {
      const activeIndex = statuses.findIndex(s => s === status );
      const stepIndex = statuses.findIndex(s => s === "activated");
      const activeErrorIndex = errorReasons.findIndex(s => s === errorReason);
      const errorIndex = errorReasons.findIndex(s => s === "sample-not-received");

      return (activeIndex > stepIndex || activeErrorIndex > errorIndex) || errorReason === "marked-rejected"  ;
    },
    failed: (_status, errorReason) => errorReason === "sample-not-received" || errorReason === "marked-rejected",
    instructions: ({ filledAssessment, test_id, viewInstructionsUrl }) => {
      if (filledAssessment) {
        return (
          <>
            <p>
              Your kit has been activated. Please make sure you post it back to
              us as soon as possible. If you need guidance on how to perform
              your test, please watch our how-to video.
            </p>
            <Button
              tag={"a"}
              href={viewInstructionsUrl}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              className="mb-4"
              label="View instructions"
            />
          </>
        );
      }

      return (
        <div>
          <p>
            We have not yet received your{" "}
            <a href={`/assessment/${test_id}/1`}>Patient Health Assessment</a>,
            please submit this as soon as possible.
          </p>
          <p>
            The information you submit will be used by our doctors to review
            your results with health and lifestyle context in mind.{" "}
            <strong>
              If we do not receive this, you will not get an accurate
              assessment.
            </strong>
          </p>
          <p>
            If you are experiencing any difficulties with the accessing or
            completing your Patient Health Assessment, please contact our
            Customer Care Team via{" "}
            <a href="mailto:care@myhealthchecked.com">
              care@myhealthchecked.com
            </a>{" "}
            who are waiting to assist you.
          </p>
        </div>
      );
    },
    graphic: KitDispatched
  },
  {
    label: "Analysed at Lab",
    isVisible: unconfirmed_result => {
      return true;
    },
    inProgress: status => status === "processing",
    isCompleted: (status, errorReason) => {
      const activeIndex = statuses.findIndex(s => s === status);
      const stepIndex = statuses.findIndex(s => s === "processing");
      const activeErrorIndex = errorReasons.findIndex(s => s === errorReason);
      const errorIndex = errorReasons.findIndex(s => s === "lab-delay");

      return activeIndex > stepIndex || activeErrorIndex > errorIndex;
    },
    failed: (_status, errorReason) => {
      if (!errorReason) return false;
      const reasons = [
        "lab-delay",
        "unexpected-results",
        "untestable",
        "inhibitory",
        // "marked-rejected"
      ];
      return reasons.includes(errorReason);
    },
    instructions: () => (
      <>
        Your kit has been received by our laboratory. Analysis of your sample is
        in progress.
      </>
    ),
    graphic: LabAnalysis
  },
  {
    label_before: "In review with a Doctor",
    label: "Reviewed by Doctor",
    isVisible: unconfirmed_result => {
      return true;
    },
    inProgress: (status, errorReason, consultation_status) => {
      if (
        consultation_status === "consultation" ||
        consultation_status === "consulted"
      ) {
        return false;
      } else {
        return status === "report-in-review" || errorReason === "gp-delay";
      }
    },
    isCompleted: (status, errorReason, consultation_status) => {
      if (
        consultation_status === "consultation" ||
        consultation_status === "consulted"
      ) {
        return true;
      } else {
        const activeIndex = statuses.findIndex(s => s === status);
        const stepIndex = statuses.findIndex(s => s === "report-in-review");
        return activeIndex > stepIndex && status !== "error";
      }
    },
    failed: (_status, errorReason) => errorReason === "gp-delay",
    graphic: Doctor,
    instructions: consultation_status =>
      !consultation_status ? (
        <>
          Your sample has been analysed by our laboratory. Your results are in
          review with our doctor.
        </>
      ) : (
        <></>
      )
  },
  {
    label: "Consultation Completed",
    label_before: "Consultation Required",
    isVisible: unconfirmed_result => {
      return unconfirmed_result;
    },
    inProgress: (status, errorReason, consultation_status) => {
      return consultation_status === "consultation" ? true : false;
    },
    isCompleted: (status, errorReason, consultation_status) => {
      return consultation_status === "consulted" ? true : false;
    },
    failed: (
      _status,
      errorReason,
      consultation_status,
      calendly_status,
      attempt_type,
      outcome
    ) => {
      return calendly_status === "reschedule_2" &&
        (outcome === "not_answered" || outcome === "not_attended")
        ? true
        : false;
    },
    graphic: PhoneConsultation
  },
  {
    label: "Results Ready",
    isVisible: unconfirmed_result => {
      return true;
    },
    label_before: "Get Results",
    inProgress: status => false,
    isCompleted: (status, errorReason) => status === "report-ready",
    failed: (
      _status,
      errorReason,
      consultation_status,
      calendly_status,
      attempt_type,
      outcome
    ) => {
      return calendly_status === "reschedule_2" &&
        (outcome === "not_answered" || outcome === "not_attended")
        ? true
        : false;
    },
    graphic: Results
  }
];

const Dashboard = props => {
  const { callApi, resultGet, isLoading, serverError } = useApis();
  const [testFlag, setTestFlag] = useState(1);
  const appointmentData = null;
  const auth = useAuth();

  const [reports, setReports] = useState(null);
  const [test, setTest] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTests = async () => {
      setLoading(true);
      try {
        const response = await axios.get(myResultUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
          }
        });

        if (response?.data) {     
          setTestFlag(response?.data?.test_kits?.data[0]?.type.includes("dna-") ? 0 : 1);
          setTest(response?.data?.test_kits?.data[0]);
        }
      } catch (error) {
        if (
          error?.response?.data?.session_expired ||
          error?.response?.data?.unauthorized
        ) {
          callApi(null, logoutUrl, "POST");
          auth.logout();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTests();
  }, [auth?.editReload]);

  // Fetches wellness reports data from the API
  useEffect(() => {
    if (test?.report_id && test.type.includes("dna-")) {
      callApi(null, wellnessReportUrl(test?.report_id), "GET");
    }
  }, [test?.report_id, test]);
  // Updates reports state when API result changes
  useEffect(() => {
    if (resultGet) {
      setReports([resultGet]);
    }
  }, [resultGet]);

  const assessment = {
    requireAssessment:
      test?.test_kit_type && test?.test_kit_type.startsWith("blood-"),
    hasFilledAssessment: test?.assessment_id,
    canEditAssessment: test?.can_edit_assessment
  };

  // Memoizes accordion items to prevent re-calculating on every render
  const memoizedReports = useMemo(
    () =>
      reports?.map(report => {
        const accordionItems = report.health_areas?.map(area => ({
          name: area.name,
          tag: area.tag,
          personalised_description: area.personalised_description,
          description: area.description,
          effect: area.effect,
          value: area.value,
          lead: area.lead,
          advice: area.advice,
          advisements: report.advice,
          snps: report.snps.filter(item => area.snps.includes(item.snp)),
          further_research_required: report.further_research_required,
          missing: area.missing
        }));
        return {
          ...report,
          accordionItems
        };
      }),
    [reports]
  );
  if (!test && !reports && (isLoading || loading))
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );
  if (serverError) return <div>Error: {serverError}</div>;
  // Render instructions
  const renderInstructions = () => {
    let nextStep = steps[currentStepIndex] || { title: () => null };

    // If next step is 'assessment, skip to another step
    if (nextStep.name === "assessment") {
      nextStep = steps[currentStepIndex + 2] || { title: () => null };
    }

    // If it is Menopause or PCOS test AND assessment wasn't filled
    // AND still can be filled - then show warning
    if (
      (test?.type === "blood-menopause" || test?.type === "blood-polycystic") &&
      !assessment.hasFilledAssessment &&
      assessment.canEditAssessment
    )
      return (
        <div>
          <p className="text-justify">
            Our records show that we have not received your completed Health
            Assessment for <strong>{toSentenceCase(test?.type_name)}</strong>,
            and without this, you will not receive your results.
          </p>

          <p className="text-justify">
            The information you submit will provide health and lifestyle context
            for the doctor who reviews your results. We will also ask you
            questions about your menstrual cycle because blood results can
            depend on the phase of a woman’s cycle.
          </p>
        </div>
      );

    if (!nextStep.instructions) return null;

    return nextStep.instructions({
      test_id: test?.id,
      viewInstructionsUrl,
      filledAssessment: !!assessment.hasFilledAssessment,
      errorReason: test?.archive_reason,
      errorReasonMessage: test?.archive_reason_message,
      consultation_status: test?.consultation_status
    });
  };
  const anyStepFailed = steps.some(step =>
    step.failed?.(test?.test_kit_status, test?.archive_reason)
  );
  const reportReady = test?.test_kit_status === "report-ready";
  const reportLink = `/my-reports/blood/${test?.report_id}`;
  const currentStep = steps.find(step =>
    step.inProgress(test?.test_kit_status, test?.archive_reason)
  );
  const currentStepIndex = steps.findIndex(step => step === currentStep);
  let viewInstructionsUrl = "https://myhealthchecked.com/help/videos/blood";
  const isSexualHealthTest = test?.type?.indexOf("sex-health") !== -1;
  if (isSexualHealthTest) {
    viewInstructionsUrl =
      "https://myhealthchecked.com/help/videos/sexual-health";
  }

  return (
    <>
      <div className="row justify-content-center dashboard-page">
        <div className="col-md-12">
          <div className="dashboard text-center text-md-start">
            <h1 className="mb-4">
              Welcome {localStorage.getItem("username").split(" ")[0]}!
            </h1>
            <p>Welcome to your personalised MyHealthChecked panel.</p>
            <h2 className="mb-4">{toSentenceCase(test?.type_name)}</h2>
            {test && testFlag === 1 ? (
              <>
                <h2>{appointmentData?.type}</h2>
                {test ? (
                  <AppointmentDetails
                    {...test}
                    testId={test?.id}
                    calendlyStatus={test?.calendly_status}
                    renderInstructions={renderInstructions}
                    unconfirmedResultMessage={
                      test?.unconfirmed_result_message
                    }
                  />
                ) : (
                  <></>
                )}
                {test?.archive_reason_message || test?.error_note ? (
                  (test?.error_note && test?.archive_reason_message) ||
                    test?.error_note ? (
                    <Alert
                      className="rounded"
                      variant="danger"
                      dismissible={false}
                    >
                      {
                        test?.bid ? (
                          <div className="">
                            <h1>Action required</h1>
                            <div>
                              <p>
                                We regret to inform you that our partner lab was unable to successfully analyze your sample(s). This may
                                happen due to several reasons such as leakage during transit, clotted samples, or heamolysis.
                              </p>
                              <p>To book a new appointment, please click on the button below.</p>
                            </div>
                            <Button color="primary" href="https://www.boots.com/wellnesstestrebook" target="_blank" label="Rebook Appointment" />
                          </div>
                        ) :
                          <ReactMarkdown>{test?.error_note}</ReactMarkdown>
                      }
                    </Alert>
                  ) : test?.archive_reason_message ? (
                    <Alert
                      className="rounded"
                      variant="danger"
                      dismissible={false}
                    >
                      <ReactMarkdown>
                        {test?.archive_reason_message}
                      </ReactMarkdown>
                    </Alert>
                  ) : null
                ) : null}

                <div className="mb-4">
                  {!!reportReady && (
                    <p>
                      Our doctor has reviewed your results. Your report has
                      been published and is ready to view.
                    </p>
                  )}
                  <div className="d-flex">
                    {!!reportReady && (
                      <Button
                        className="me-3"
                        href={reportLink}
                        color="primary"
                        label="View report"
                      />
                    )}
                    {/* If has assessment, show button to view it */}
                    {assessment.hasFilledAssessment &&
                      !assessment.canEditAssessment && (
                        <Button
                          label="View health assessment"
                          className="outline-primary view-health-assessment-btn"
                          href={`/assessment-details/${test?.id}`}
                          color="primary"
                          variant="outline-primary"
                        />
                      )}
                  </div>
                </div>
                {
                  <TimelineItem
                    test={test}
                    steps={steps}
                    assessment={assessment}
                    hasFilledAssessment={!!assessment.hasFilledAssessment}
                    canEditAssessment={!!assessment?.canEditAssessment}
                    currentStepIndex={currentStepIndex}
                    anyStepFailed={anyStepFailed}
                  />
                }
              </>
            ) : test &&
              testFlag === 0 &&
              test.test_kit_status !== "published" ? (
              <>{test && <WellnessTimeline test={test} />}</>
            ) : !isLoading &&
              test?.type?.includes("dna") &&
              test?.test_kit_status == "published" &&
              memoizedReports?.length > 0 ? (
              memoizedReports?.map(report => (
                <>
                  <div className="container-fluid report-highlight-wrapper">
                    <div className="pt-3 pb-1">
                      <ReportHighlightSection
                        items={report.accordionItems}
                        test_name={test?.type_name}
                        report_id={test?.report_id}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </>
              ))
            ) : (
              !loading &&
              !isLoading && (
                <>
                  <p>
                    Once you've completed your first test, you'll receive
                    personalised advice and support to improve your health.
                  </p>
                  <div className="pt-sm-4 mt-4 mb-4">
                    <div className="container activate-box-wrapper">
                      <div className="row">
                        <div className="col-md-12">
                          <LinkCard
                            to="https://myhealthchecked.com/our-tests"
                            image={Test}
                            title="Buy tests"
                            button_text="Buy now"
                            button_append_class="my-button-class"
                          >
                            <p>
                              Get started today by buying a wellness test with
                              us!
                            </p>
                          </LinkCard>
                        </div>
                        <div className="col-md-12">
                          <LinkCard
                            to="/activate"
                            image={Pink}
                            title="Activate test"
                            button_text="Activate"
                            button_append_class="my-button-class"
                          >
                            <p>
                              Already have a test and need to get it
                              activated?
                            </p>
                          </LinkCard>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
          {test?.type?.includes("blood-") && !test?.bId && (
            <AssessmentMessage
              assessment={assessment}
              status={test?.test_kit_status}
              test_id={test?.id}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
