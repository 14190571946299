import React from "react";
import Router from "./Router";

export default ({isHcpLive}) => {
  return (
    <>
      <Router isHcpLive={isHcpLive} />
    </>
  );
};
