import React, { useState } from "react";
import { Button } from "../../stories/Buttons/Button";
import { downloadDnaPdf, downloadDnaTxt } from "../../utils/constants";
import useApis from "../../services/useAPI";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { BoxArrowInRight } from "react-bootstrap-icons";

/**
 * A component for downloading reports and raw data files.
 * Provides buttons to download DNA-related reports in PDF or TXT format.
 * @returns {JSX.Element} The rendered ReportDownloadSection component.
 */

const ReportDownloadSection = () => {
  const { isLoading } = useApis();
  const { id } = useParams();
  const auth = useAuth();

  // States for download actions
  const [isPdfDownloading, setIsPdfDownloading] = useState(false);
  const [isTxtDownloading, setIsTxtDownloading] = useState(false);

  // Handlers for respective API calls
  const handleDownloadRawData = async () => {
    try {
      setIsTxtDownloading(true);  // Mark txt download as in-progress
      const response = await axios.get(downloadDnaTxt(id), { responseType: 'blob' });

      // Gets the file name from the Content-Disposition header (if available)
      const contentDisposition = response.headers['content-disposition'];
      const fileNameMatch = contentDisposition?.match(/filename="([^"]+)"/);
      const fileName = fileNameMatch?.[1] ?? 'raw_data.txt';

      // Creates a link element to trigger the download
      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsTxtDownloading(false);
      if (response.status == 200) {
        auth?.showToast(
          "Success",
          "File downloaded successfully!",
          "../assets/mhc.png",
          <BoxArrowInRight />
        );
      }
    } catch (error) {
      console.error("Error downloading raw data:", error);
      setIsTxtDownloading(false);
    }
  };

  const handleDownloadPdfReport = async () => {
    try {
      setIsPdfDownloading(true);
      const response = await axios.get(downloadDnaPdf(id), { responseType: 'blob' });

      // Gets the file name from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      const fileNameMatch = contentDisposition?.match(/filename="([^"]+)"/);
      const fileName = fileNameMatch?.[1] ?? 'report.pdf';

      // Creates a link element to trigger the download
      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsPdfDownloading(false);
      if (response.status == 200) {
        auth?.showToast(
          "Success",
          "File downloaded successfully!",
          "../assets/mhc.png",
          <BoxArrowInRight />
        );
      }
    } catch (error) {
      console.error("Error downloading PDF report:", error);
      setIsPdfDownloading(false);
    }
  };

  return (
    <div>
      {/* Button for downloading raw data */}
      <Button
        className="me-2 float-right btn btn-outline-primary"
        label={isTxtDownloading ? "Downloading..." : "Download raw data"}
        onClick={handleDownloadRawData}
        disabled={isLoading || isTxtDownloading}
      />

      {/* Button for downloading PDF report */}
      <Button
        className="float-right btn btn-primary"
        label={isPdfDownloading ? "Downloading..." : "Download PDF Report"}
        onClick={handleDownloadPdfReport}
        disabled={isLoading || isPdfDownloading}
      />
    </div>
  );
};

export default ReportDownloadSection;