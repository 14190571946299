import Form from "react-bootstrap/Form";
import FormInput from "./FormInput";
import { Button } from "../Buttons/Button";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";
import { Recaptcha } from "../../components/Recaptcha";
import PasswordStrength from "../../components/PasswordStrength";
import ToggleButtons from "../Buttons/ToggleButtons";
import { FormGroup } from "react-bootstrap";
import { previousFifteenDays, todayFormatted, YoutubeEmbed } from "../../utils";
import { useAuth } from "../../components/AuthContext/AuthContext";
import PromptIfDirty from "./PromptIfDirty";

// Helper function to modify field properties as needed
export const modifyFields = (fields, initialValues, auth) => {
  return fields?.map(field => {
    // Check for smoking-related conditions
    if (
      initialValues.smoking === "Yes" &&
      field.name === "cigarettes_per_day"
    ) {
      return { ...field, disabled: false, required: true, hide: false };
    }

    // Check for alcohol-related conditions
    if (
      initialValues.alcohol === "Yes" &&
      field.name === "alcohol_units_per_week"
    ) {
      return { ...field, disabled: false, required: true, hide: false };
    }

    // Check for physical activity-related conditions
    if (initialValues.physical_activity === "Yes") {
      // If YES:
      if (field.name === "physical_activity") {
        return {
          ...field,
          helpText:
            "Please tell us how many hours you spent on physical activity last week"
        };
      } else if (
        [
          "light_activity_hours_per_week",
          "moderate_activity_hours_per_week",
          "vigorous_activity_hours_per_week"
        ]?.includes(field.name)
      ) {
        return { ...field, disabled: false, required: true, hide: false };
      }
    }

    // Check for sexually active conditions
    if (initialValues.sexually_active === "Yes") {
      if (
        ["partners", "recent_sex", "had_unprotected_sex"]?.includes(field.name)
      ) {
        return { ...field, disabled: false, required: true, hide: false };
      }
    }

    // Check for unprotected sex conditions
    if (
      initialValues.had_unprotected_sex === "Yes" &&
      field.name === "unprotected_sex_type"
    ) {
      return {
        ...field,
        disabled: false,
        check_group_required: true,
        check_group_hide: false
      };
    }

    // Check for STI symptoms
    if (
      initialValues.sti_symptoms === "Yes" &&
      field.name === "sti_symptoms_description"
    ) {
      return { ...field, disabled: false, required: true, hide: false };
    }

    // Check for potential STI exposure
    if (
      initialValues.potential_sti_exposure === "Yes" &&
      field.name === "potential_sti_exposure_type"
    ) {
      return { ...field, check_group_required: true, check_group_hide: false };
    }

    // Modify height and weight fields based on unit type
    if (field.name === "height") {
      return {
        ...field,
        label: `Please enter your height (${
          auth.unitType.currentUnit === "Imperial" ? "inch" : "cm"
          })`
      };
    } else if (field.name === "weight") {
      return {
        ...field,
        label: `Please enter your weight (${
          auth.unitType.currentUnit === "Imperial" ? "lbs" : "kg"
          })`
      };
    }

    // Check for diagnosed conditions
    if (initialValues.was_diagnosed === "Yes" && field.check_box_group) {
      const modifiedGroup = field.check_box_group.map(innerField => ({
        ...innerField,
        disabled: false,
        hide: false
      }));
      return { ...field, check_box_group: modifiedGroup };
    }

    // Check for medications
    if (initialValues.medication === "Yes" && field.check_box_group) {
      const modifiedGroup = field.check_box_group.map(innerField => ({
        ...innerField,
        disabled: false,
        hide: false
      }));
      return { ...field, check_box_group: modifiedGroup };
    }

    // Check for "Other" in `condition` array
    if (
      Array.isArray(initialValues.condition) &&
      initialValues.condition?.includes("Other")
    ) {
      if (field.label === "Please specify in the box below") {
        return { ...field, disabled: false, hide: false, required: true };
      }
    }

    // Check for "Other" in `medications` array
    if (
      Array.isArray(initialValues.medications) &&
      initialValues.medications?.includes("Other")
    ) {
      if (
        field.label === "Please specify in the box below" ||
        (field.name === "other_description" && field.as === "textarea")
      ) {
        return { ...field, disabled: false, hide: false, required: true };
      }
    }

    // Return the unmodified field if no conditions match
    return field;
  });
};

const DynamicForm = props => {
  const auth = useAuth();
  const recaptchaRef = useRef(null);
  const [toggleValue, setToggleValue] = useState(null);
  const [genderIdentity, setGenderIdentity] = useState(null);
  const [formData, setFormData] = useState(null);
  const [modifiedFields, setModifiedFields] = useState([]); // Track modified fields in state

  const handleCheckboxChange = (e, groupName, values, setFieldValue) => {
    const { value, checked } = e.target;
    const updatedValues = values[groupName] ? [...values[groupName]] : [];

    if (checked) {
      // Add the value if checked and not already in the array
      if (!updatedValues?.includes(value)) {
        updatedValues.push(value);
      }
    } else {
      // Remove the value if unchecked
      const index = updatedValues.indexOf(value);
      if (index > -1) {
        updatedValues.splice(index, 1);
      }
    }

    // Update the Formik state
    setFieldValue(groupName, updatedValues);
  };

  const generateInitialValues = (fields, data) => {
    const initialValues = {};
    fields?.forEach(field => {
      if (field.type !== "button" || field.type !== "submit") {
        initialValues[field.name] =
          data?.[field.name] !== undefined ? data[field.name] : "";
      }
    });
    return initialValues;
  };

  useEffect(() => {
    // Safely destructure formData, defaulting to an empty object if formData is null or undefined
    const {
      smoking = "",
      alcohol = "",
      physical_activity = "",
      sexually_active = "",
      had_unprotected_sex = "",
      sti_symptoms = "",
      potential_sti_exposure = "",
      height = "",
      weight = "",
      units = "",
      condition = [],
      medications = []
    } = formData || {};

    const isModified = () => {
      return (
        smoking === "Yes" ||
        alcohol === "Yes" ||
        physical_activity === "Yes" ||
        sexually_active === "Yes" ||
        had_unprotected_sex === "Yes" ||
        sti_symptoms === "Yes" ||
        potential_sti_exposure === "Yes" ||
        height ||
        weight ||
        units ||
        condition?.includes("Other") ||
        medications?.includes("Other")
      );
    };

    if (isModified()) {
      setModifiedFields(prev => {
        const newFields = modifyFields(props.fields, formData, auth);
        return prev !== newFields ? newFields : prev;
      });
    } else {
      setModifiedFields(prev => {
        return prev !== props.fields ? props.fields : prev;
      });
    }

    // Units and toggle
    if (props.initialValues?.units) {
      auth.setUnitType(props.initialValues.units);
      setToggleValue(prev =>
        prev !== props.initialValues.units ? props.initialValues.units : prev
      );
    }

    // Sex
    if (props.initialValues?.sex) {
      setToggleValue(prev =>
        prev !== props.initialValues.sex ? props.initialValues.sex : prev
      );
    }

    // Gender identity
    if (typeof props.initialValues?.identify_with_gender === "boolean") {
      setGenderIdentity(prev => {
        const newGenderIdentity = props.initialValues.identify_with_gender
          ? "yes"
          : "no";
        return prev !== newGenderIdentity ? newGenderIdentity : prev;
      });
    }
  }, [
    formData?.smoking,
    formData?.alcohol,
    formData?.physical_activity,
    formData?.sexually_active,
    formData?.had_unprotected_sex,
    formData?.sti_symptoms,
    formData?.potential_sti_exposure,
    formData?.height,
    formData?.weight,
    formData?.units,
    formData?.condition,
    formData?.medications,
    props.initialValues?.sex,
    props.initialValues?.identify_with_gender,
    props.fields
  ]);

  const fields = modifiedFields.length > 0 ? modifiedFields : props.fields; // Use modifiedFields if available

  return (
    <Formik
      validationSchema={props.validationSchema}
      onSubmit={(values, { resetForm }) => {
        delete values.undefined;
        props.onSubmit?.(values);
        resetForm();
        setTimeout(() => {
          if (recaptchaRef.current) {
            recaptchaRef.current.resetRecaptcha();
          }
        }, 3000);
      }}
      initialValues={{
        ...(props.initialValues ||
          generateInitialValues(props.modify ? modifiedFields : props.fields))
      }}
      // kept commented for future reference
      // validateOnChange={props?.validateOnChange ? true : props.validateOnChange}
      // validateOnBlur={props?.validateOnBlur ? true : props.validateOnBlur}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue
      }) => {
        setFormData(values);
        return (
          <Form noValidate onSubmit={handleSubmit} className={props.className}>
            {props.blockRefresh && <PromptIfDirty />}
            {fields?.map((field, index) => {
              if (field.name === "sample_collection_time") {
                return null;
              }

              if (field.type === "iframe") {
                return (
                  <div className="mt-4 mb-4">
                    <Form.Group
                      key={field.controlId}
                      controlId={field.controlId}
                    >
                      <YoutubeEmbed embedId={field.embedId} />
                    </Form.Group>
                  </div>
                );
              }
              return (
                <Form.Group key={field.controlId} controlId={field.controlId}>
                  {field.type === "button" || field.type === "submit" ? (
                    <div
                      className={`d-flex justify-content-${
                        field.linkName ? "between" : "end"
                        } align-items-center`}
                    >
                      {field.linkName && (
                        <a href={field.href} className="small-link">
                          {field.linkName}
                        </a>
                      )}
                      <div>
                        {field.aboveBtnMsg && <p>{field?.aboveBtnMsg}</p>}
                        {!field.btnGroup && (
                          <Button
                            type={field.type}
                            size={field.size}
                            className={`${field?.className} float-end`}
                            variant={field.variant}
                            label={field.label}
                          />
                        )}
                        {field.btnGroup?.map(field => (
                          <Button
                            type={field.type}
                            size={field.size}
                            className={`${field?.className} float-end`}
                            variant={field.variant}
                            label={field.label}
                          />
                        ))}
                      </div>
                    </div>
                  ) : field.type === "recaptcha" ? (
                    <Recaptcha
                      key={field.controlId}
                      ref={recaptchaRef}
                      errors={errors}
                      onChange={response =>
                        setFieldValue("recaptcha_token", response)
                      }
                      className="recaptcha-container"
                    />
                  ) : field.type === "button-group" ? (
                    <div>
                      <div className={"form-group " + field?.className}>
                        <Form.Label className="labels">
                          {field.required ? (
                            <>
                              {field.label}
                              <span className="required"></span>
                            </>
                          ) : (
                            field.label
                          )}
                        </Form.Label>
                        <div
                          className={
                            props.btnSecondary
                              ? "d-flex flex-wrap align-items-center barcode-2-btns"
                              : props.genderButton
                                ? "d-flex flex-wrap align-items-center btn-group toggle-btn-group"
                                : "d-flex flex-wrap align-items-center"
                          }
                        >
                          {field?.buttons?.map(button => (
                            <>
                              {button.aboveBtnMsg && (
                                <>
                                  <p>{button?.aboveBtnMsg}</p> <br />
                                </>
                              )}
                              <Button
                                key={button.controlId}
                                type={button.type}
                                size={button.size}
                                className={button.className}
                                variant={button.variant}
                                label={button.label}
                                href={button.href}
                                active={button.active}
                                onClick={
                                  button.label === "Previous step"
                                    ? () => auth.handlePreviousStep()
                                    : button.onClick
                                }
                              />
                            </>
                          ))}
                        </div>
                        {field.helpText && (
                          <small className="form-text text-muted mt-2">
                            {field.helpText}
                          </small>
                        )}
                      </div>
                    </div>
                  ) : field.type === "toggle-buttons" &&
                    field.name !== "units" ? (
                    <>
                      <div className="form-group toggle-btn-group">
                        <ToggleButtons
                          field={field}
                          toggleValueProp={toggleValue}
                          genderIdentityProp={genderIdentity}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                        />
                        <small className="form-text text-muted mt-2">
                          {field.helpText}
                        </small>
                      </div>
                    </>
                  ) : field.type === "help-text" ? (
                    <small className="form-text text-muted mt-2">
                      {field.helpText}
                    </small>
                  ) : field.name === "sample_collection_date" ? (
                    <div className="row" key={index}>
                      {props.fields
                        .filter(
                          f =>
                            f.name === "sample_collection_date" ||
                            f.name === "sample_collection_time"
                        )
                        ?.map((f, subIndex) => (
                          <FormGroup
                            className={`col-md-${f.colSize} ${f.className ||
                              ""}`}
                            key={subIndex}
                          >
                            <FormInput
                              id={`input-${f.controlId}`}
                              key={f.controlId}
                              min={previousFifteenDays}
                              max={todayFormatted}
                              maxlength={f.maxLength}
                              className={`form-group`} // Keeps "form-group" and appends f.className if it exists
                              type={f.type}
                              {...f}
                              name={f.name}
                              value={values[f.name]}
                              helpTextMarkdown={f.helpTextMarkdown}
                              onChange={handleChange}
                              toggleValueProp={toggleValue}
                              error={errors[f.name]}
                              touched={!!touched[field.name]}
                              validationError={props.validationError}
                            />
                          </FormGroup>
                        ))}
                    </div>
                  ) : field.name === "consent_steps" ? (
                    <FormInput
                      key={index}
                      type="checkbox"
                      name={field.name}
                      id={field.controlId}
                      heading={field.heading}
                      message={field.message}
                      checked={field.checked[field.value]} // Pass the checked state
                      onChange={field.onChange} // Pass the onChange handler
                      validationError={props.validationError}
                    />
                  ) : (
                    <>
                      {" "}
                      <h1 className="my-4">{field.h1}</h1>
                      {!field.check_box_group && !field.btnGroup && (
                        <FormInput
                          id={`input-${field.controlId}`}
                          key={field.controlId}
                          className="form-group"
                          type={field.type}
                          {...field}
                          name={field.name}
                          maxLength={field.maxLength}
                          value={values[field.name]}
                          helpTextMarkdown={field.helpTextMarkdown}
                          toggleValueProp={toggleValue}
                          onChange={e => {
                            handleChange(e);
                            if (field.type === "password") {
                              props.setPassword?.(e.target.value);
                            }
                          }}
                          error={errors[field.name]}
                          touched={touched[field.name] ? "true" : "false"}
                          image={field.image}
                          validationError={props.validationError}
                        />
                      )}
                    </>
                  )}
                  {field.btnGroup && (
                    <div
                      className={`d-flex justify-content-between align-items-center flex-wrap`}
                    >
                      {field.btnGroup?.map((btn, idx) => (
                        <Button
                          key={idx} // Ensure each button has a unique key
                          type={btn.type}
                          size={btn.size}
                          className={`${btn?.className}`}
                          variant={btn.variant}
                          label={btn.label}
                          onClick={
                            btn.label === "Previous step"
                              ? () => auth.handlePreviousStep()
                              : btn?.onClick
                          }
                        />
                      ))}
                    </div>
                  )}
                  <div className="form-group">
                    {field.check_group_label && !field.check_group_hide && (
                      <Form.Label className="labels">
                        {field.check_group_label}
                        <span className="required"></span>
                      </Form.Label>
                    )}
                    {field.check_box_group &&
                      !field.check_group_hide &&
                      (errors?.unprotected_sex_type ||
                        errors?.potential_sti_exposure_type) && (
                        <div className="invalid-feedback mb-2">
                          {errors?.unprotected_sex_type ||
                            errors?.potential_sti_exposure_type}
                        </div>
                      )}
                    {field.check_box_group &&
                      !field.check_group_hide &&
                      field.check_box_group.map(check => (
                        <FormInput
                          id={`input-${check.controlId}`}
                          key={check.controlId}
                          className="form-group"
                          type={check.type}
                          {...check}
                          name={check.name}
                          value={values[check.label]}
                          toggleValueProp={toggleValue}
                          helpTextMarkdown={check.helpTextMarkdown}
                          onChange={e => {
                            handleCheckboxChange(
                              e,
                              check.name,
                              values,
                              setFieldValue
                            );
                            if (check.type === "password") {
                              props.setPassword?.(e.target.value);
                            }
                          }}
                          checked={values.unprotected_sex_type?.includes(
                            props.label
                          )}
                          error={errors[check.name]}
                          touched={touched[check.name]}
                          noLabel={true}
                          image={check.image}
                          validationError={props.validationError}
                        />
                      ))}
                  </div>
                  {field.name === "password" &&
                    field.type === "password" &&
                    props.password && (
                      <PasswordStrength password={props.password} />
                    )}
                </Form.Group>
              );
            })}
          </Form>
        );
      }}
    </Formik>
  );
};

export default DynamicForm;

DynamicForm.defaultProps = {
  fields: [],
  validationSchema: null,
  onSubmit: undefined
};

DynamicForm.propTypes = {
  validationSchema: PropTypes.object,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      controlId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      buttons: PropTypes.array,
      as: PropTypes.string,
      required: PropTypes.bool,
      placeholder: PropTypes.string,
      size: PropTypes.string,
      readOnly: PropTypes.bool,
      disabled: PropTypes.bool,
      aria_describedby: PropTypes.string,
      helpText: PropTypes.string,
      linkName: PropTypes.string,
      href: PropTypes.string,
      className: PropTypes.string,
      variant: PropTypes.string,
      FormFieldImage: PropTypes.node
    })
  ),
  onSubmit: PropTypes.func
};
