import { useParams } from "react-router-dom";
import React from "react";
import LavenderAsset from "../../assets/images/lavender_barcode.png";
import BlueAsset from "../../assets/images/blue_barcode.png";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { secondaryBarcodeFields } from "../../stories/Forms/fields";

/**
 * SecondaryBarcode component
 *
 * This component renders a form for users to confirm their secondary barcode.
 * It fetches the barcode from localStorage and displays it in a read-only field.
 * The user can either confirm the barcode is correct or navigate away if incorrect.
 *
 * @returns {JSX.Element} The rendered form for confirming the secondary barcode.
 */


function SecondaryBarcode() {
  const { colour } = useParams();
  const { primaryBarcode } = useParams();

  return (
    <div className="row justify-content-center secondary-barcode-page">
      <div className="col-md-12">
        <h1 className="mb-4">Check your second barcode</h1>
        <p className="text-muted">
          Please check and confirm your{" "}
          {colour === "urine" ? "BLUE" : colour?.toUpperCase()} barcode is
          correct.
        </p>
        <div className="text-left py-2">
          <img
            loading="eager"
            alt={colour}
            src={colour === "lavender" ? LavenderAsset : BlueAsset}
            className="img-fluid rounded-lg"
            width={227}
            height={85}
          />
        </div>
        <DynamicForm
          fields={secondaryBarcodeFields(primaryBarcode)}
          className={"barcode-form"}
          btnSecondary={true}
        />
      </div>
    </div>
  );
}

export default SecondaryBarcode;
