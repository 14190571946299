import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { FormCheck, Button, Row, Col, FormLabel } from "react-bootstrap";
import FormInput from "./FormInput";
import PromptIfDirty from "./PromptIfDirty";
import { modifyFields } from "./DynamicForm";
import { useAuth } from "../../components/AuthContext/AuthContext";

let medicationValue;
let diagnosedValue;

/**
 * A form component for displaying a checkbox group, used for medical conditions or medications.
 * This form allows users to select multiple options and submit the data, with dynamic field modification based on the user's selections.
 * The form is built using Formik for form handling and validation, and React Bootstrap for layout and styling.
 **/

const CheckboxGroupForm = ({
  fields,
  onSubmit,
  type,
  initialValues,
  blockRefresh,
  is_assessment_completed,
  ...props
}) => {
  const auth = useAuth();
  const [formData, setFormData] = useState();
  const [title, setTitle] = useState();
  const [modifiedFields, setModifiedFields] = useState([]);

  const fieldArray = modifiedFields || fields;

  useEffect(() => {
    if (is_assessment_completed) {
      diagnosedValue = initialValues?.condition?.length > 0 ? "Yes" : "No";
      medicationValue = initialValues?.medications?.length > 0 ? "Yes" : "No";
    } else {
      diagnosedValue = "No";
      medicationValue = "No";
    }
  }, [initialValues]);

  useEffect(() => {
    if (formData?.was_diagnosed === "Yes" || formData?.medication === "Yes") {
      setTitle(true)
      setModifiedFields(modifyFields(fields, formData, auth));
    } else if (
      formData?.was_diagnosed === "No" ||
      formData?.was_diagnosed === "Please choose" ||
      formData?.medication === "No" ||
      formData?.medication === "Please choose"
    ) {
      setTitle(false)
      setModifiedFields(fields);
    }
  }, [
    formData?.was_diagnosed,
    formData?.medication,
    fields,
    auth,
    formData?.condition,
    formData?.medications
  ]);

  const customHandleChange = (e, name, values, setFieldValue) => {
    const { checked, value } = e.target;

    const currentValues = values[name] || [];
    const updatedValues = checked
      ? [...currentValues, value]
      : currentValues.filter(v => v !== value);

    setFieldValue(name, updatedValues);
  };

  return (
    <div>
      <Formik
        initialValues={{
          checked: initialValues?.condition || initialValues?.medications || [],
          other_description:
            initialValues?.other_description || initialValues?.details || "",
          was_diagnosed: initialValues?.condition?.length > 0 && "Yes" || diagnosedValue,
          medication: initialValues?.medications?.length > 0 && "Yes" || medicationValue,
          ...initialValues
        }}
        validationSchema={props.validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={async values => {
          const medicalConditions = { ...values };
          const otherDetails = values.other_description;

          const formattedData =
            type === "medical_conditions"
              ? {
                medical_conditions: {
                  conditions: medicalConditions,
                  other_description: otherDetails
                }
              }
              : {
                medications: {
                  medications: medicalConditions,
                  other_description: otherDetails
                }
              };
          await onSubmit(formattedData);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => {
          setFormData(values);
          return (
            <Form className="assessment-form">
              {blockRefresh && <PromptIfDirty />}
              <div className="container-fluid">
                <Row>
                  <Col md={12}>
                    {fieldArray?.map(field => {
                      if (Array.isArray(field?.check_box_group)) {
                        return (
                          <Row key={`row-${field.controlId}`}>
                            {
                              (title) &&
                              <div className="form-group m-0">
                                <FormLabel className="labels form-label mt-2">
                                  Please tick all that apply:
                                  <span className="required"></span>
                                </FormLabel>
                              </div>
                            }
                            {errors && (
                              <div className="invalid-feedback mb-2">
                                {errors?.condition || errors?.medications}
                              </div>
                            )}
                            {field.check_box_group.map(
                              (checkboxField, index) => {
                                if (
                                  checkboxField.type === "checkbox" &&
                                  !checkboxField.hide
                                ) {
                                  return (
                                    <Col md={6} key={checkboxField.controlId}>
                                      <FormCheck
                                        type="checkbox"
                                        label={checkboxField.label}
                                        name={checkboxField.name}
                                        value={checkboxField.value}
                                        onChange={e =>
                                          customHandleChange(
                                            e,
                                            checkboxField.name,
                                            values,
                                            setFieldValue
                                          )
                                        }
                                        checked={(
                                          values[checkboxField.name] ||
                                          initialValues?.condition
                                        )?.includes(checkboxField?.value)}
                                        className={`${checkboxField.className}`}
                                        aria-describedby={
                                          checkboxField.aria_describedby
                                        }
                                      />
                                    </Col>
                                  );
                                }
                                return null;
                              }
                            )}
                          </Row>
                        );
                      }

                      if (
                        field.type !== "button" &&
                        field.type !== "submit" &&
                        !field.btnGroup &&
                        field.type !== "group-label"
                      ) {
                        return (
                          <FormInput
                            id={`input-${field.controlId}`}
                            key={field.controlId}
                            className="form-group"
                            type={field.type}
                            {...field}
                            name={field.name}
                            value={values[field.name]}
                            error={errors[field.name]}
                            touched={touched[field.name]}
                            onChange={e =>
                              setFieldValue(field.name, e.target.value)
                            }
                            isInvalid={
                              touched[field.name] && !!errors[field.name]
                            }
                          />
                        );
                      }

                      return (
                        !field.hide &&
                        field.type === "group-label" && (
                          <p className="mt-4" key={`label-${field.controlId}`}>
                            {field.label}
                          </p>
                        )
                      );
                    })}
                  </Col>
                </Row>
              </div>

              <div
                className={`d-flex flex-wrap justify-content-between align-items-center`}
              >
                {fieldArray?.map(field => {
                  if (field.btnGroup) {
                    return (
                      <>
                        {field.btnGroup.map(btn => (
                          <Button
                            key={btn.controlId}
                            type={btn.type}
                            onClick={
                              btn.label === "Previous step"
                                ? () => auth.handlePreviousStep()
                                : btn?.onClick
                            }
                            size={btn.size}
                            variant={btn.variant}
                            className={btn.className}
                          >
                            {btn.label}
                          </Button>
                        ))}
                      </>
                    );
                  }
                  return null;
                })}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
    )
};

export default CheckboxGroupForm;
