import React, { useCallback, useEffect, useState } from "react";
import { Alert, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { AppointmentInfo } from "./AppointmentInfo";
import Calendly from "./Calendly";
import axios from "axios";
import { useAuth } from "../../components/AuthContext/AuthContext";

let globalAttempts;
let time;
let showAppointmentDetails = false;
let appointmentMissed = false;
let noDisplay;

const AppointmentDetails = ({

  calendlyStatus: initialCalendlyStatus,
  appointmentMissed: initialAppointmentMissed,
  unconfirmedResultMessage,
  note,
  notes,
  testId,
  eventStatus,
  userTime,
  meetDetails,
  renderInstructions,
  scheduleAttempt,
  consultationStatus,
  outcome,
  testName,
}) => {

  const auth = useAuth();
  const [eventStatusState, setEventStatus] = useState(eventStatus || "");
  const [scheduleAttemptState, setScheduleAttempt] = useState(scheduleAttempt);
  const [meetDetailsState, setMeetDetails] = useState(meetDetails);
  const [utcDateTime, setUtcDateTime] = useState('');
  const [notesState, setNotesState] = useState(note || null);
  const [displayCalendly, setDisplayCalendly] = useState('timeline__step--display ap');
  const [calendlyStatus, setCalendlyStatus] = useState(initialCalendlyStatus);
  const [showButton, setShowButton] = useState(false);

  const meetingDetails = (time) => {

    if (time !== undefined || (utcDateTime !== undefined && utcDateTime !== '')) {

      const dateObj = time ? new Date(time) : new Date(utcDateTime);

      const userDateTime = dateObj.toLocaleString(undefined, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      setMeetDetails(userDateTime);

      const _24BeforeMeeting = new Date(dateObj.getTime() - 24 * 60 * 60 * 1000);
      const currentTime = new Date();

      if (currentTime < _24BeforeMeeting && eventStatus !== 'reschedule_2') {
        setShowButton(true);
      } else {
        setShowButton(false);
      }

      return userDateTime;
    }
    setMeetDetails('');
  };

  const toggleAppointmentDetails = useCallback(() => {

    if (calendlyStatus !== null && consultationStatus !== 'consulted') {
      if (!(outcome === 'not_answered' || outcome === 'not_attended')) {
        showAppointmentDetails = true
      } else {
        showAppointmentDetails = false
      }
    }
  }, [calendlyStatus, consultationStatus, outcome, auth?.editReload]);

  const handleEdit = useCallback(async () => {
    setDisplayCalendly('p-2');
  }, [displayCalendly]);

  const updatePostData = useCallback(
    (attempts, start_time, description, status, display) => {

      if (status === 'reschedule_2') {
        setShowButton(false)
      }
      if (!calendlyStatus) {
        setCalendlyStatus("scheduled");
        toggleAppointmentDetails();
        checkIfAppointmentMissed();
      }
      globalAttempts = attempts;
      setEventStatus(status);

      setScheduleAttempt(description);

      setMeetDetails(start_time);

      setNotesState(attempts);
      noDisplay = true;

      setDisplayCalendly(display);
      auth.setEditReload(start_time)
    },
    [outcome, calendlyStatus, noDisplay, auth?.editReload, globalAttempts, scheduleAttemptState, meetDetails, notesState, displayCalendly]
  );






  const checkIfAppointmentMissed = useCallback(() => {
    if (calendlyStatus) {
      const missed = outcome === "not_answered" || outcome === "not_attended";
      appointmentMissed = missed
    }
  }, [calendlyStatus, outcome, auth?.editReload]);

  useEffect(() => {
    toggleAppointmentDetails();
    checkIfAppointmentMissed();
  }, [auth.editReload, outcome, eventStatus, calendlyStatus]);

  useEffect(() => {
    const fetchCalendlyDetails = async () => {
      if (calendlyStatus !== null) {
        const getUrl = `/api/dashboard/calendly/appointment-details/${testId}/`;

        axios.get(getUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then(r => {
          setEventStatus(r?.data?.status);
          setScheduleAttempt(r?.data?.description);
          setUtcDateTime(r?.data?.start_time);
          time = meetingDetails(r?.data?.start_time)
        })
      }
    };

    fetchCalendlyDetails();
  }, [calendlyStatus, testId, meetDetails, outcome, auth?.editReload]);

  useEffect(() => {

    if (calendlyStatus !== null && consultationStatus !== 'consulted') {
      if (!(outcome === 'not_answered' || outcome === 'not_attended')) {
        showAppointmentDetails = true
      } else {
        showAppointmentDetails = false
      }
    }
  }, [calendlyStatus, consultationStatus, outcome, auth?.editReload]);

  useEffect(() => {
    if (calendlyStatus !== null || calendlyStatus !== '') {
      if (outcome === 'not_answered' || outcome === 'not_attended') {
        appointmentMissed = true
      } else {
        appointmentMissed = false
      }
    } else {
      appointmentMissed = false
    }
  }, [calendlyStatus, outcome, auth.editReload]);

  return (
    <>
      <div className="my-3 border-0">
        <h2 className="text-md-left">{testName}</h2>
        {unconfirmedResultMessage && calendlyStatus === null && outcome !== 'consulted' && !showAppointmentDetails ? (
          <>
            <Alert className="rounded" variant="danger" dismissible={false}>
              <h1>Phone consultation required</h1>
              <ReactMarkdown>{unconfirmedResultMessage}</ReactMarkdown>
            </Alert>
            {(note || notesState) && (
              <Alert className="rounded notes-alert" variant="warning">
                <ReactMarkdown>{notesState || note}</ReactMarkdown>
              </Alert>
            )}
            <Container className={`p-2 ${noDisplay && 'd-none'}`}>
              <Calendly
                id={testId}
                event_status={eventStatusState}
                updatePostData={updatePostData}
                meetingDetails={meetingDetails}
              />
            </Container>
          </>
        ) : appointmentMissed !== true && showAppointmentDetails === true && outcome !== 'consulted' && showAppointmentDetails ? (
          <>
            <AppointmentInfo
              user_time={userTime}
              meet_details={meetDetailsState}
              show_button={showButton}
              setShowButton={setShowButton}
              event_status={eventStatusState}
              test_id={testId}
              note={globalAttempts}
              notes={notesState}
              updatePostData={updatePostData}
              meetingDetails={meetingDetails}
              meeting_time={time}
              handleEdit={handleEdit}
              displayCalendly={displayCalendly}
              setDisplayCalendly={setDisplayCalendly}
            />
          </>
        ) : appointmentMissed === true && showAppointmentDetails !== true && outcome !== null ? (
          <>
            <Alert className="rounded" variant="danger" dismissible={false}>
              <h1>You missed your appointment!</h1>
              <ReactMarkdown>{unconfirmedResultMessage}</ReactMarkdown>
            </Alert>
            {(note || notesState) && (
              <Alert className="mt-2 rounded notes-alert" variant="warning">
                <ReactMarkdown>{notesState || note}</ReactMarkdown>
              </Alert>
            )}
            {scheduleAttemptState !== "phone_3" && (
              <Container className={`p-2 ${noDisplay && 'd-none'}`}>
                <Calendly
                  id={testId}
                  updatePostData={updatePostData}
                  event_status={eventStatusState}
                  meetingDetails={meetingDetails}
                />
              </Container>
            )}
          </>
        ) : (
          renderInstructions()
        )}
      </div>
    </>
  );
};

export default AppointmentDetails;
