import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from '../../components/AuthContext/AuthContext'
import { Row, Col, Spinner } from 'react-bootstrap';
import { Button } from '../../stories/Buttons/Button';
import {
    assessmentStatusUrl,
    phlebotomyAssessmentStatusUrl,
    assessementFinalSubmitUrl,
    phlebotomyAssessementFinalSubmit
} from "../../utils/constants";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";
import { EmojiSmile } from "react-bootstrap-icons";
import { XSquare } from "react-bootstrap-icons";

/**
 * FinalStep Component
 * Displays the final step of the health assessment process.
 * Allows the user to review their details and submit the assessment.
 */

function FinalStep() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { test_kit_id, bid, test_type } = useParams();;
    const [isShTest, setIsShTest] = useState();
    const [isHormoneTest, setIsHormoneTest] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const formId = location.pathname.split('/').at(-1)
    const { setCompletedSteps, setIsCompleted, setStep, allStepData, } = auth;
    const [is_assessment_completed, set_Is_assessment_completed] = useState(false);
    const isPhlebotomy = location.pathname.includes('boots');
    const currentPath = window.location.pathname;
    const parts = currentPath.split('/');
    const thisStep = parts[parts.length - 1];
    localStorage.setItem("previous_step", thisStep)

    const { callApi, resultGet, resultPost, serverError } = useApis();

    const test_kit_step = location.pathname.split('/').at(-1)

    const handlePreviousStep = useCallback((test_kit_id) => {
        const { test_kit_type } = resultGet?.test_kit_details || {};

        const hasExtraStep = test_kit_type?.includes('sex') || test_kit_type?.includes('menopause') || test_kit_type?.includes('polycystic')
        const backBy = hasExtraStep ? 1 : 2;
        setCompletedSteps(prevSteps => prevSteps - backBy); // Use functional update to access the latest value
        setStep(prevStep => prevStep - backBy);
        if (isPhlebotomy && test_type && bid) {
            navigate(`/bloods/boots/assessment/${test_type}/${bid}/${parseInt(formId) - 2}`, { state: { ...auth.allStepData } })
        } else {
            navigate(`/assessment/${test_kit_id}/${parseInt(test_kit_step) - backBy}`)
        }

    }, [test_kit_step, resultGet]);

    useEffect(() => {
        if (resultPost) {
            setShowSpinner(false);
        }
    }, [resultPost])

    useEffect(() => {
        if (isPhlebotomy && test_type && bid) {
            callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
        }
        else if (!isPhlebotomy && !test_type && !bid) {
            test_kit_id &&
                localStorage.setItem("test_kit_id", location?.state?.test_kit_id);
            callApi(
                null,
                `${assessmentStatusUrl}${test_kit_id ||
                localStorage.getItem("test_kit_id")}`,
                "GET",
                true
            );
        }
    }, [auth.step, auth.completedSteps, resultPost]);

    useEffect(() => {
        if (resultGet?.test_kit_details?.test_kit_type?.includes('sex')) {
            setIsShTest(true)
            setIsHormoneTest(false)
        }
        else if (resultGet?.test_kit_details?.test_kit_type?.includes('menopause') || resultGet?.test_kit_details?.test_kit_type?.includes('polycystic')) {
            setIsHormoneTest(true)
            setIsShTest(false)
        }
    }, [resultGet]);

    const preprocessStepData = (data) => {
        const processedData = { ...data };

        // If physical_activity is "No", set related fields to null
        if (processedData.activity?.physical_activity === "No") {
            processedData.activity.light_activity_hours_per_week = null;
            processedData.activity.moderate_activity_hours_per_week = null;
            processedData.activity.vigorous_activity_hours_per_week = null;
        }

        // If alcohol is "No", set alcohol_units_per_week to null
        if (processedData.alcohol?.alcohol === "No") {
            processedData.alcohol.alcohol_units_per_week = null;
        }

        // If smoking is "No", set cigarettes_per_day to null
        if (processedData.smoking?.smoking === "No") {
            processedData.smoking.cigarettes_per_day = null;
        }

        // Add any other similar conditions for body, if necessary
        if (!processedData.body?.height || !processedData.body?.weight) {
            processedData.body.height = null;
            processedData.body.weight = null;
        }

        // Check medical_conditions: if "Other" is not included, set other_description to null
        if (!processedData.medical_conditions?.condition?.includes("Other")) {
            processedData.medical_conditions.other_description = "";
        }

        // Check medications: if "Other" is not included, set other_description to null
        if (!processedData.medications?.medications?.includes("Other")) {
            processedData.medications.other_description = "";
        }

        return processedData;
    };

    const finalSubmit = () => {
        const processedData = preprocessStepData(allStepData);
        setShowSpinner(true);
        if (isPhlebotomy && test_type && bid) {
            callApi(
                { ...processedData },
                phlebotomyAssessementFinalSubmit(bid),
                "POST"
            );
        } else {
            callApi(
                processedData,
                `${assessementFinalSubmitUrl}/${test_kit_id || location?.state?.test_kit_id}`,
                "POST",
                true
            );
        }
    };

    useEffect(() => {
        if (resultPost && isPhlebotomy && test_type && bid) {
            auth?.showToast(
                "Assessment Completed",
                "All steps completed successfully",
                "../assets/mhc.png",
                <EmojiSmile />
            );
            setIsCompleted(parseInt(formId));
            setCompletedSteps(parseInt(formId) + 1);
            setStep(parseInt(formId));
            navigate(`/bloods/boots/success-page/${test_type}/${bid}`);
        }
    }, [resultPost, isPhlebotomy, test_type, bid]);


    useEffect(() => {
        let mergedData;

        if (resultGet?.list_of_steps) {
            auth.setTotalSteps(resultGet?.list_of_steps?.includes('phlebotomist_booking_info') ? 6 : 7)
        }

        if (!resultGet?.assessment_values) return;

        mergedData = {
            medical_conditions: {
                condition: allStepData?.medical_conditions?.condition ?? resultGet?.assessment_values?.medical_conditions?.condition,
                other_description: allStepData?.medical_conditions?.other_description ?? resultGet?.assessment_values?.medical_conditions?.other_description,
            },
            medications: {
                medications: allStepData?.medications?.medications ?? resultGet?.assessment_values?.medications?.medications,
                other_description: allStepData?.medications?.other_description ?? resultGet?.assessment_values?.medications?.other_description,
            },
            body: {
                ...(allStepData?.body || resultGet?.assessment_values?.body),
            },
            smoking: {
                ...(allStepData?.smoking || resultGet?.assessment_values?.smoking),
            },
            alcohol: {
                ...(allStepData?.alcohol || resultGet?.assessment_values?.alcohol),
            },
            activity: {
                ...(allStepData?.activity || resultGet?.assessment_values?.activity),
            },
        };

        if (resultGet.test_kit_details?.test_kit_type?.includes('sex')) {
            mergedData = {
                ...mergedData,
                sexual_health: {
                    ...(allStepData?.sexual_health || resultGet?.assessment_values?.sexual_health),
                },
            }
        } else if (resultGet.test_kit_details?.test_kit_type?.includes('menopause') ||
            resultGet.test_kit_details?.test_kit_type?.includes('polycystic')) {
            mergedData = {
                ...mergedData,
                menstrual: {
                    ...(allStepData?.menstrual || resultGet?.assessment_values?.menstrual),
                },
            }
        }

        auth.setAllStepData(mergedData);
    }, [resultGet, allStepData]);

    useEffect(() => {
        if (resultPost && !test_type && !bid) {
            auth?.showToast(
                "Assessment Completed",
                "All steps completed successfully",
                "../assets/mhc.png",
                <EmojiSmile />
            );
            navigate(`/assessment-details/${test_kit_id ? test_kit_id : location?.state?.test_kit_id}`);
        }
    }, [resultPost, resultGet, allStepData])

    useEffect(() => {
        set_Is_assessment_completed(resultGet?.is_assessment_completed)
        if (serverError) {
            auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
        }
    }, [resultGet, serverError])

    if (showSpinner) {
        return (
            <div className="text-center">
                <Spinner animation="border" variant="info" />
            </div>
        );
    }

    return (
        <div className="row justify-content-center start-assessment-page">
            <div className="col-md-12">
                <StepProgressBar
                    steps={auth.totalSteps}
                    currentStep={7}
                    completedSteps={isShTest || isHormoneTest ? 6 : 5}
                    isCompleted={isShTest || isHormoneTest ? 6 : 5}
                    is_assessment_completed={is_assessment_completed}
                />
                <div className="container-fluid">
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <div className="assessment-form mt-5">
                                <h1 className="mb-4">Assessment summary</h1>
                                <p>
                                    Our doctor will review your test results in the context of
                                    this Health Assessment. Please check that all details are
                                    correct before submitting, as you will not be able to edit
                                    your answers after submission.
                                </p>
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <Button
                                        className="btn btn-gray mt-2 float-start"
                                        variant="secondary"
                                        label="Previous step"
                                        onClick={() => handlePreviousStep(test_kit_id)}
                                    />
                                    <Button
                                        className="btn mt-2 btn-primary float-end"
                                        label="Assessment submit"
                                        onClick={() => finalSubmit()}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>
    )
}

export default FinalStep