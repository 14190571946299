import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

/**
 * NavItem component
 * Renders a navigation item button with an icon, label, and optional subtitle
 * and status indicator.
 * @returns {JSX.Element} The rendered navigation item button.
 */

const NavItem = ({ item, isCurrent, setCurrentItem, getStatusIcon, status }) => {

  return (
    <Button
      className={`horizontal-section-nav-button ${isCurrent ? "horizontal-section-nav-button--current" : ""}`}
      onClick={() => setCurrentItem(item.name)}
      style={{
        backgroundColor: item.background,
        borderColor: !isCurrent ? item.background : undefined,
      }}
      variant="light"
    >
      {item.icon && <img src={item.icon} alt={item.label} />}
      <div className="horizontal-section-nav-button-label">
        <span className="horizontal-section-nav-button-title">{item.label}</span>
        {item.subtitle && (
          <span className="horizontal-section-nav-button-subtitle">{item.subtitle}</span>
        )}
      </div>
      {item?.status && (
        <span
          className={`horizontal-section-nav-button-status horizontal-section-nav-button-status--${status}`}
        >
          <FontAwesomeIcon icon={getStatusIcon(status)} />
        </span>
      )}
    </Button>
  );
};

export default NavItem;
