import React, { useState, useEffect } from "react";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { createAccount } from "../../stories/Forms/fields";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { createAccountSchema } from "../../stories/Forms/validationSchema";
import { createAccountUrl } from "../../utils/constants";
import { BoxArrowInRight, XSquare } from "react-bootstrap-icons";
import useApis from "../../services/useAPI";

/**
 * CreateAccount component handles the user registration process.
 * @returns {JSX.Element} A form to create a new user account.
 */

const CreateAccount = () => {
  const auth = useAuth();
  const { callApi, serverError, resultPost, loading } = useApis();
  const [password, setPassword] = useState("");
  const [finished, setFinished] = useState(false);
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    if (serverError && !toastShown) {
      auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
      setToastShown(true);
    }
  }, [serverError, toastShown, auth]);

  // Handle POST response for account creation
  useEffect(() => {
    if (resultPost?.data && !toastShown) {
      auth?.showToast(
        "Success",
        "Account created successfully!",
        "../assets/mhc.png",
        <BoxArrowInRight />
      );
      setFinished(true);
      setToastShown(true);
    }
  }, [resultPost, toastShown, auth]);

  // Function to handle account creation
  const handleCreateAccount = async values => {
    const payload = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      password: values.password,
      recaptcha_token: values.recaptcha_token
    };

    // Resets toast control state before making API call
    setToastShown(false);

    await callApi(payload, createAccountUrl, "POST");
  };

  return (
    <div className="initial-form-wrapper">
      <div className="row justify-content-center">
        <div className="col-md-8">
            <h1 className="mb-4"> Create account </h1>
            {finished ? (
              <div className="lead">
                <p>
                  Please check your email to confirm your new account and login.
                </p>
                <p>You can safely close this window.</p>
              </div>
            ) : (
              <>
                <p className="lead">
                  Create a new MyHealthChecked account to activate kits and view
                  results.
                </p>
                <DynamicForm
                  fields={createAccount}
                  onSubmit={handleCreateAccount}
                  password={password}
                  setPassword={setPassword}
                  validationSchema={createAccountSchema}
                  isLoading={loading}
                  error={serverError}
                />
              </>
            )}

        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
