import React, { useState, useEffect } from "react";
import { SplitScreen } from "./components/SplitScreen";
import Page from "./Page";
import SideBar from "./Page/SideBar";
import Toaster from "./stories/Toasts/Toaster";
import { useAuth } from "./components/AuthContext/AuthContext";
import { Container, Spinner } from "react-bootstrap";
import Flag from "../src/components/Flag";
import NotFound from "./components/NotFound";
import ButtonModal from "./stories/Modal/ButtonModal";
import useApis from "./services/useAPI";
import { useNavigate } from "react-router-dom";

function App() {
  const auth = useAuth();
  const navigate = useNavigate();
  const mfa = JSON.parse(localStorage.getItem("mfa_enabled")) || null
  const [show, setShow] = useState(!mfa);
  const [isHcpLive, setIsHcpLive] = useState(null)
  const [isPhlebotomyRoute, setIsPhlebotomyRoute] = useState(null)

  const { callApi, resultGet, isLoading, serverError } = useApis();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('isLoggedIn'))) {
      callApi(null, '/api/dashboard/bank-holiday', "GET")
    }
  }, [isHcpLive, isPhlebotomyRoute, localStorage.getItem('isLoggedIn')])

  useEffect(() => {
    if (window.location.pathname.includes('/boots')) {
      setIsPhlebotomyRoute(true)
    }
    if (resultGet) {
      setIsHcpLive(resultGet?.data?.is_hcp_live)
    }

  }, [resultGet])

  // Handle hiding the MFA modal
  const handleHide = () => {
    localStorage.setItem("defered_mfa", true);
    setShow(false);
  };

  const handleMFA = async () => {
    localStorage.setItem("mfa_enabled", true);
    navigate("/enable-mfa");
    setShow(false);
  };

  return (
    <>
      <main>
        <Flag />
        <SplitScreen isSidebar={true} rightWeight={4}>
          <SideBar isHcpLive={isHcpLive} isPhlebotomyRoute={isPhlebotomyRoute} />
          <Container fluid className="mt-4 mt-md-5">
            {isLoading ? (
              <div className="text-center">
                <Spinner animation="border" variant="info" />
              </div>
            ) : !isHcpLive && isPhlebotomyRoute ? (
              <NotFound />
            ) : (
              <Page isHcpLive={isHcpLive} />
            )}
            {!mfa &&
              !JSON.parse(localStorage.getItem("defered_mfa")) &&
              JSON.parse(localStorage.getItem("isLoggedIn")) && (
                <ButtonModal
                  heading={"Enable MFA"}
                  message={
                    "Multi-factor Authentication (also known as Two Factor Authentication or 2FA) improves your account security by requiring a second means of authentication when logging in."
                  }
                  onClick={handleMFA}
                  onHide={handleHide}
                  show={show}
                />
              )}
          </Container>
          <Toaster newToast={auth?.toast} />
        </SplitScreen>
      </main>
    </>
  );
}

export default App;