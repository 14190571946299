import React, { useEffect, useState } from "react";
import { useAuth } from '../../components/AuthContext/AuthContext'
import { bodyFields } from '../../stories/Forms/fields';
import { bodyAssessmentSchema } from '../../stories/Forms/validationSchema';
import { Row, Col } from 'react-bootstrap';
import DynamicForm from '../../stories/Forms/DynamicForm';
import {
    assessmentStatusUrl,
    assessmentValidateUrl,
    phlebotomyAssessmentStatusUrl,
} from "../../utils/constants";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";
import { XSquare } from "react-bootstrap-icons";

let submissionData;

/**
 * BodyForm Component
 * This component handles the body assessment form, including height, weight, smoking, and alcohol consumption.
 * It integrates with APIs for fetching and submitting data, handles user navigation, and displays progress.
 * @returns {JSX.Element} The body assessment form.
 */

function BodyForm() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { test_kit_id } = params;
    const [data, setData] = useState({});
    const [is_assessment_completed, set_Is_assessment_completed] = useState(false);
    const { bid } = useParams();
    const { test_type } = useParams();
    const formId = location.pathname.split('/').at(-1)
    const { setCompletedSteps, setIsCompleted, setStep } = auth;

    const isPhlebotomy = location.pathname.includes('boots');

    const { callApi, resultGet, resultPost, serverError } = useApis();

    const handleAssessmentSubmit = (values) => {
        // Remove keys with undefined values
        Object.keys(values).forEach((key) => {
            if (values[key] === undefined) {
                delete values[key];
            }
        });

        submissionData = {
            body: {
                height: values.height,
                weight: values.weight,
                units: values?.units || "Metric",
            },
            smoking: {
                smoking: values.smoking,
                cigarettes_per_day: values.cigarettes_per_day,
            },
            alcohol: {
                alcohol: values.alcohol,
                alcohol_units_per_week: values.alcohol_units_per_week,
            },
            step: "body_smoking_alcohol",
        };
        if (submissionData) {
            callApi(
                {
                    user_id: JSON.parse(localStorage.getItem("user_id")),
                    ...submissionData || { ...data?.body, ...data?.smoking, ...data?.alcohol, step: 'body_smoking_alcohol' }
                },
                `${assessmentValidateUrl}`,
                "POST",
                true
            );
        }
        setData(submissionData)
        auth.setAllStepData((prevStep) => ({ ...prevStep, ...submissionData }));
        setIsCompleted(parseInt(formId) + 1); // Increment the step based on the latest value
        setCompletedSteps(prevSteps => prevSteps + 1); // Use functional update to access the latest value
        setStep(prevStep => prevStep + 1);
    }

    useEffect(() => {

        if (resultPost) {
            setIsCompleted(parseInt(formId)); // Increment the step based on the latest value
            setCompletedSteps(parseInt(formId) + 1); // Use functional update to access the latest value
            setStep(parseInt(formId));
            if (isPhlebotomy && test_type && bid) {
                navigate(`/bloods/boots/assessment/${test_type}/${bid}/${parseInt(formId) + 1}`, { state: { ...auth.allStepData } })
            } else {
                navigate(`/assessment/${test_kit_id}/${parseInt(formId) + 1}`, { state: { ...auth.allStepData } })
            }
        }
        if (isPhlebotomy && test_type && bid) {
            callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
        }
        else if (!isPhlebotomy && !test_type && !bid) {
            location?.state?.test_kit_id &&
                localStorage.setItem("test_kit_id", location?.state?.test_kit_id);
            callApi(
                null,
                `${assessmentStatusUrl}${test_kit_id ||
                localStorage.getItem("test_kit_id")}`,
                "GET",
                true
            );
        }
    }, [auth.step, auth.completedSteps, auth.editReload, resultPost]);
    useEffect(() => {
        if (resultGet) {

            auth.setTotalSteps(resultGet?.list_of_steps?.includes('phlebotomist_booking_info') ? 6 : 7)


            submissionData = {
                body: {
                    height: resultGet?.assessment_values?.body?.height || auth?.allStepData?.body?.height,
                    weight: resultGet?.assessment_values?.body?.weight || auth?.allStepData?.body?.weight,
                    units: resultGet?.assessment_values?.body?.units || auth?.allStepData?.body?.units || "Metric",
                },
                smoking: {
                    smoking: resultGet?.assessment_values?.smoking?.smoking || auth?.allStepData?.smoking?.smoking,
                    cigarettes_per_day: resultGet?.assessment_values?.smoking?.cigarettes_per_day || auth?.allStepData?.smoking?.cigarettes_per_day,
                },
                alcohol: {
                    alcohol: resultGet?.assessment_values?.alcohol?.alcohol || auth?.allStepData?.alcohol?.alcohol,
                    alcohol_units_per_week: resultGet?.assessment_values?.alcohol?.alcohol_units_per_week || auth?.allStepData?.alcohol?.alcohol_units_per_week,
                },
                step: "body_smoking_alcohol",
            }
            setData(submissionData)
        }
    }, [resultGet])

    useEffect(() => {
        set_Is_assessment_completed(resultGet?.is_assessment_completed)

        if(serverError){
            auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
        }
    }, [resultGet, serverError])

    return (

        <div className="row justify-content-center start-assessment-page">
            <div className="col-md-12">
                <StepProgressBar
                    steps={auth.totalSteps}
                    currentStep={2}
                    completedSteps={1}
                    isCompleted={1}
                    is_assessment_completed={is_assessment_completed}
                />
                <div className="container-fluid">
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <div className="mt-5">
                                <h1 className="mb-4">Height and weight</h1>
                                <div className="assessment-form">
                                    <Col xs={12}>
                                        <DynamicForm
                                            className="mb-4"
                                            fields={bodyFields}
                                            onSubmit={handleAssessmentSubmit}
                                            autoHideError={true}
                                            modify={true}
                                            blockRefresh={true}
                                            modifyFields={true}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            validationSchema={bodyAssessmentSchema}
                                            initialValues={
                                                {
                                                    ...data?.body,
                                                    ...data?.smoking,
                                                    ...data?.alcohol
                                                }
                                            }
                                        />
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default BodyForm
