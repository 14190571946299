import React, { useEffect, useState } from "react";
import { AssessmentMessage } from "../Health Assessment/AssesmentMessage";
import TimelineItem from "./TimelineItem";
import WellnessTimeline from "./WellnessTimeline";
import DisplayItems from "./DisplayItems";
import { myResultUrl } from "../../utils/constants";
import useApis from "../../services/useAPI";
import { useParams } from "react-router-dom";
import { steps } from "../Dashboard/Dashboard";
import { Alert, Spinner } from "react-bootstrap";
import { Button } from "../../stories/Buttons/Button";
import AppointmentDetails from "../Dashboard/AppointmentDetails";
import Markdown from "react-markdown";
import { toSentenceCase } from "../../utils/utils";
import { useAuth } from "../../components/AuthContext/AuthContext";

/**
 * TestKitDetails component fetches and displays detailed information about a specific test kit.
 * It shows information such as test kit status, assessment details, and provides navigation
 * to the assessment or timeline of the test.
 *
 * @returns {JSX.Element} The rendered TestKitDetails component.
 */

const TestKitDetails = () => {
  const params = useParams();
  const auth = useAuth();
  const { test_kit_id } = params;
  // API hook
  const { callApi, resultGet, isLoading } = useApis();
  // Toggles between tests (for demo)
  const [testFlag, setTestFlag] = useState(1);
  const [showButton, setShowButton] = useState(false);
  const [meetDetails, setMeetDetails] = useState("");
  const [utcDateTime, setUtcDateTime] = useState("");

  const meetingDetails = time => {
    if (
      time !== undefined ||
      (utcDateTime !== undefined && utcDateTime !== "")
    ) {
      const dateObj = time ? new Date(time) : new Date(utcDateTime);

      const userDateTime = dateObj.toLocaleString(undefined, {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      setMeetDetails(userDateTime);

      const _24BeforeMeeting = new Date(
        dateObj.getTime() - 24 * 60 * 60 * 1000
      );
      const currentTime = new Date();

      if (currentTime < _24BeforeMeeting) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }

      return userDateTime;
    }
    setMeetDetails("");
  };

  // Fetches test kit details
  useEffect(() => {
    callApi(null, `${myResultUrl}${test_kit_id}/`, "GET");
  }, [auth?.editReload]);

  useEffect(() => {
    if (resultGet?.data?.test_kit_type?.includes("dna-")) {
      setTestFlag(0);
    }
  }, [resultGet?.data?.test_kit_type]);

  // Shows loading or error states
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );
  }

  // Selects the current test based on testFlag
  const selectedTest = resultGet?.data;

  // Shows message if no test details are available
  if (!selectedTest) return <div>No test details available.</div>;

  // Updated fieldsArray based on selectedTest
  const fieldsArray = Object.keys(getFields(selectedTest)).map(key => ({
    name: key,
    ...getFields(selectedTest)[key]
  }));

  const assessment = {
    requireAssessment:
      selectedTest?.test_kit_type &&
      selectedTest?.test_kit_type.startsWith("blood-"),
    hasFilledAssessment: selectedTest?.assessment_id,
    canEditAssessment: selectedTest?.can_edit_assessment
  };

  const renderInstructions = () => {
    let nextStep = steps[currentStepIndex] || { title: () => null };

    // If next step is 'assessment, skip to another step
    if (nextStep.name === "assessment") {
      nextStep = steps[currentStepIndex + 2] || { title: () => null };
    }

    // If it is Menopause or PCOS test AND assessment wasn't filled
    // AND still can be filled - then show warning
    if (
      (selectedTest?.test_kit_type === "blood-menopause" ||
        selectedTest?.test_kit_type === "blood-polycystic") &&
      !assessment.hasFilledAssessment &&
      assessment.canEditAssessment
    )
      return (
        <div>
          <p className="text-justify">
            Our records show that we have not received your completed Health
            Assessment for <strong>{selectedTest?.test_kit_type_name}</strong>,
            and without this, you will not receive your results.
          </p>

          <p className="text-justify">
            The information you submit will provide health and lifestyle context
            for the doctor who reviews your results. We will also ask you
            questions about your menstrual cycle because blood results can
            depend on the phase of a woman’s cycle.
          </p>
        </div>
      );

    if (!nextStep.instructions) return null;

    return nextStep.instructions({
      test_id: selectedTest?.id,
      viewInstructionsUrl,
      filledAssessment: !!assessment.hasFilledAssessment,
      errorReason: selectedTest?.archive_reason,
      errorReasonMessage: selectedTest?.archive_reason_message,
      consultation_status: selectedTest?.consultation_status
    });
  };
  const anyStepFailed = steps.some(step =>
    step.failed?.(selectedTest?.test_kit_status, selectedTest?.archive_reason)
  );

  const currentStep = steps.find(step =>
    step.inProgress(selectedTest?.test_kit_status, selectedTest?.archive_reason)
  );
  const currentStepIndex = steps.findIndex(step => step === currentStep);
  let viewInstructionsUrl = "https://myhealthchecked.com/help/videos/blood";
  const isSexualHealthTest =
    selectedTest?.test_kit_type?.indexOf("sex-health") !== -1;
  if (isSexualHealthTest) {
    viewInstructionsUrl =
      "https://myhealthchecked.com/help/videos/sexual-health";
  }
  const reportLink = `/my-reports/blood/${selectedTest?.report_id || ""}`;
  const reportReady = selectedTest?.test_kit_status === "report-ready";

  return (
    <div className="row justify-content-center dashboard-page">
      <div className="col-md-12">
        <div className="dashboard">
          <h1>{toSentenceCase(selectedTest.test_kit_type_name)}</h1>
          {selectedTest.archive_reason_message || selectedTest.error_note ? (

            (selectedTest.error_note &&
              selectedTest.archive_reason_message) ||
              selectedTest.error_note ? (

              <Alert className="rounded" variant="danger" dismissible={false}>

                {
                  selectedTest?.bid ? (
                    <div className="">
                      <h1>Action required</h1>
                      <div>
                        <p>
                          We regret to inform you that our partner lab was unable to successfully analyze your sample(s). This may
                          happen due to several reasons such as leakage during transit, clotted samples, or heamolysis.
                        </p>
                        <p>To book a new appointment, please click on the button below.</p>
                      </div>
                      <Button color="primary" href="https://www.boots.com/wellnesstestrebook" target="_blank" label="Rebook Appointment" />
                    </div>
                  ) :
                    <Markdown>{selectedTest?.error_note}</Markdown>
                }
              </Alert>
            ) : selectedTest.archive_reason_message ? (
              <Alert className="rounded" variant="danger" dismissible={false}>
                <Markdown>{selectedTest.archive_reason_message}</Markdown>
              </Alert>
            ) : null
          ) : (
            <></>
          )}
          <div className="mb-4">
            {!!reportReady && (
              <p>
                Our doctor has reviewed your results. Your report has
                been published and is ready to view.
              </p>
            )}
            <div className="d-flex">
              {!!reportReady && (
                <Button
                  className="me-3"
                  href={reportLink}
                  color="primary"
                  label='View report'
                />
              )}
              {/* If has assessment, show button to view it */}
              {testFlag == 1 &&
                assessment.hasFilledAssessment &&
                !assessment.canEditAssessment && (
                  <Button
                    className="outline-primary"
                    href={`/assessment-details/${selectedTest?.id}`}
                    color="primary"
                    variant="outline-primary"
                    label='View health assessment'
                  />
                )}
            </div>
          </div>
          {testFlag === 1 ? (
            <>
              <AppointmentDetails
                {...selectedTest}
                testId={selectedTest?.id}
                calendlyStatus={selectedTest?.calendly_status}
                renderInstructions={renderInstructions}
                unconfirmedResultMessage={
                  selectedTest?.unconfirmed_result_message
                }
                meetingDetails={meetingDetails}
                showButton={showButton}
              />
              <TimelineItem
                test={{ ...selectedTest }}
                steps={steps}
                assessment={assessment}
                hasFilledAssessment={!!assessment.hasFilledAssessment}
                canEditAssessment={!!assessment?.canEditAssessment}
                currentStepIndex={currentStepIndex}
                anyStepFailed={anyStepFailed}
              />
            </>
          ) : testFlag === 0 ? (
            <WellnessTimeline test={{ ...selectedTest }} />
          ) : null}

          {selectedTest?.test_kit_type?.includes("blood-") &&
            !selectedTest?.bId && (
              <AssessmentMessage
                assessment={assessment}
                status={selectedTest?.test_kit_status}
                test_id={selectedTest?.id}
              />
            )}
          <h2 className="py-2">Test summary</h2>
          <DisplayItems fields={fieldsArray} data={selectedTest} />
        </div>
      </div>
    </div>
  );
};

export default TestKitDetails;

// Function to get conditional fields
const getFields = selectedTest => {
  if (selectedTest?.test_kit_type?.startsWith("dna")) {
    return {
      primary_booking_reference: {
        title: "Booking Reference",
        fmt: "inline-code"
      },
      primary_barcode: { title: "Barcode", fmt: "inline-code" },
      test_kit_type_name: { title: "Test Kit Type", fmt: "as-title" },
      test_kit_status_name: { title: "Status", fmt: "as-title" }
    };
  } else {
    return {
      test_kit_type_name: { title: "Test Kit Type", fmt: "as-title" },
      test_kit_status_name: { title: "Status", fmt: "as-title" },
      sex: { title: "Gender at Birth", fmt: "as-title" },
      dob: { title: "Date of Birth", fmt: "date" },
      primary_barcode: { fmt: "inline-code" },
      secondary_barcode: { fmt: "inline-code" }
    };
  }
};
