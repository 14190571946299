import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useFormikContext } from "formik";
import ReactMarkdown from "react-markdown";
import { Recaptcha } from "../../components/Recaptcha";
import ToggleButtons from "../Buttons/ToggleButtons";
import {
  handleTrackingNumberChange,
  handleChangeWithContext
} from "../../utils";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const convert = require("convert-units");

let errorMessage;
function FormInput(props) {
  const { values, touched, errors, setFieldValue } = useFormikContext();
  const [inputValue, setInputValue] = useState(values[props.name] || "");
  const [password, setPassword] = useState(props.value);
  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // Ensure the input value is in sync with the Formik value
    setInputValue(values[props.name] || "");

    if (auth.unitType.prevUnit !== auth.unitType.currentUnit) {
      if (auth.unitType.currentUnit === "Metric") {
        const metricConverted = {
          height: Number(
            convert(values["height"])
              .from("in")
              .to("cm")
              .toFixed(2)
          ),
          weight: Number(
            convert(values["weight"])
              .from("lb")
              .to("kg")
              .toFixed(2)
          )
        };
        if (props.name === "height") {
          setInputValue(metricConverted.height);
          setFieldValue(props.name, metricConverted.height);
        } else if (props.name === "weight") {
          setInputValue(metricConverted.weight);
          setFieldValue(props.name, metricConverted.weight);
        }
      } else if (auth.unitType.currentUnit === "Imperial") {
        const imperialConverted = {
          height: Number(
            convert(values["height"])
              .from("cm")
              .to("in")
              .toFixed(2)
          ),
          weight: Number(
            convert(values["weight"])
              .from("kg")
              .to("lb")
              .toFixed(2)
          )
        };
        if (props.name === "height") {
          setInputValue(imperialConverted.height);
          setFieldValue(props.name, imperialConverted.height);
        } else if (props.name === "weight") {
          setInputValue(imperialConverted.weight);
          setFieldValue(props.name, imperialConverted.weight);
        }
      }
    }
  }, [auth]);

  const barcodeError = props?.validationError?.toLowerCase().includes("blood test kit") || props?.validationError?.toLowerCase().includes("barcode") || props?.validationError?.toLowerCase().includes("already started activating") || props?.validationError?.toLowerCase().includes("has already been");
  const trackingNumberError = props?.validationError?.toLowerCase().includes("tracking");


  if (barcodeError) {
    errorMessage = {
      ...(props.name === "code" &&
        props.validationError && {
        [props.name]: props.validationError
      })
    };
  } else if (trackingNumberError) {
    errorMessage = {
      ...(props.name === "trackingNumber" &&
        props.validationError && {
        [props.name]: props.validationError
      })
    };
  }

  const errorArray = errors
  const combinedErrors = {
    ...errorArray,
    ...errorMessage,

  };

  const handleBlur = e => {
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleChange = e => {
    if (!props.readOnly && !props.disabled) {
      const newValue = e.target.value;
      setInputValue(newValue);
      // setOriginalValue(newValue); // Update the original value
      props.onChange(e);

      if (props.type === "password") {
        setPassword(newValue);
      }
    }
  };

  return (
    <>
      {!props.hide && (
        <Form.Group className={"form-group"} id={props.controlId || props.name}>
          {props.image && (
            <div>
              <img
                src={props.image}
                alt="Field visual aid"
                className={"form-image mb-2" + props.imgClassname}
              />
            </div>
          )}
          <Form.Label className="labels">
            {props.required ? (
              <>
                {!props.noLabel && props.label}
                <span className="required"></span>
              </>
            ) : (
              !props.noLabel && props.label
            )}
          </Form.Label>

          {props.type === "radio" ? (
            <div>
              {props.choices.map((choice, index) => (
                <Form.Check
                  key={index}
                  type="radio"
                  id={`${props.name}-${choice.value}`}
                  name={props.name}
                  value={choice.value}
                  label={choice.label}
                  checked={inputValue === choice.value}
                  onChange={handleChange}
                  isInvalid={!!(touched[props.name] && errors[props.name])}
                />
              ))}
            </div>
          ) : props.type === "checkbox" && props.name === "consent_steps" ? (
            <Form.Check
              type="checkbox"
              id={`default-${props.id}`}
              label={
                <>
                  <strong>{props.heading}</strong>
                  <br />
                  {props.message && (
                    <small className="form-text text-muted">
                      {props.message}
                    </small>
                  )}
                </>
              }
              checked={props.checked}
              onChange={props.onChange}
              isInvalid={props.validationError || !!errors[props.name]}
            />
          ) : props.type === "checkbox" ? (
            <Form.Check
              key={props.controlId}
              type="checkbox"
              id={props.controlId}
              label={props.label}
              name={props?.name} // Set all checkboxes to 'checked' array
              value={props.label} // Use the 'name' as value to distinguish each checkbox
              onChange={props?.onChange} // Handle change for Formik
              checked={
                props?.values?.checked?.includes(props.label) ||
                values.unprotected_sex_type?.includes(props.label) ||
                values?.potential_sti_exposure_type?.includes(props.label)
              } // Reflect checked status based on initialValues
              className={props.className}
              aria-describedby={props.aria_describedby}
              isInvalid={
                props.validationError ||
                (touched[props.name] && !!errors[props.name])
              }
            />
          ) : props.type === "select" ? (
            <Form.Select
              aria-label="Default select example"
              placeholder={props.placeholder}
              value={props.value}
              isInvalid={props.touched && !!props.error}
              {...props}
              onChange={handleChange}
            >
              <option>{props.placeholder}</option>
              {props.options.map((option, index) => (
                <option key={index} value={option?.value || option}>
                  {option?.label || option}
                </option>
              ))}
            </Form.Select>
          ) : props.type === "toggle-buttons" ? (
            <>
              <div className="form-group toggle-btn-group">
                <ToggleButtons
                  field={props}
                  toggleValueProp={props.toggleValueProp}
                  noLabel={true}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
                <small className="form-text text-muted mt-2">
                  {props.helpText}
                </small>
              </div>
            </>
          ) : (
            <>
              {props.type !== "password" && (
                <Form.Control
                  id={props.controlId || props.name}
                  type={props.type}
                  name={props.name}
                  as={props.as || Form.Control}
                  required={props.required}
                  placeholder={props.placeholder}
                  size={props.size}
                  readOnly={props.readOnly}
                  disabled={props.disabled}
                  min={props.min}
                  max={props.max}
                  maxLength={props.maxLength}
                  value={
                    props.name === "secondary_barcode"
                      ? localStorage.getItem("secondary_barcode_id") || ""
                      : props.name === "primary_contact_no"
                        ? values[props.name] || "+44"
                        : inputValue || values[props.name]
                  }
                  aria-describedby={props.aria_describedby}
                  isInvalid={
                    props.validationError ||
                    (props.touched && !!props.error) ||
                    !!(touched[props.name] && (combinedErrors[props.name]))
                  }
                  onChange={(e) => {
                    let value = e.target.value;
                    if (props.name === "primary_contact_no") {
                      value = value.replace(/\s+/g, '');
                      if (!value.startsWith('+44')) {
                        value = `+44${value.replace(/^(\+)?44/, '')}`;
                      }
                    }
                    handleChangeWithContext(
                      {
                        ...e,
                        target: {
                          ...e.target,
                          value,
                        },
                      },
                      props,
                      setInputValue,
                      setFieldValue,
                      handleTrackingNumberChange,
                      auth
                    );
                  }}
                  onBlur={props.onBlur}
                />
              )}
            </>
          )}
          {props.type === "password" && (
            <InputGroup>
              <Form.Control
                aria-describedby={props.aria_describedby}
                className="password-field"
                as={props?.as || Form.Control}
                disabled={props.disabled}
                isInvalid={props.touched && !!props.error}
                name={props.name}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                required={props.required}
                size={props.size}
                type={showPassword ? "text" : "password"}
                value={password}
              />
              <InputGroup.Text
                className="password-input"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                <span>{showPassword ? <Eye /> : <EyeSlash />}</span>
              </InputGroup.Text>
              {(combinedErrors[props.name] || errors[props.name]) && !props.group_name && (
                <Form.Control.Feedback type="invalid">
                  {combinedErrors[props.name] || errors[props.name]}
                </Form.Control.Feedback>
              )}
            </InputGroup>

          )}
          {props.type === "recaptcha" && (
            <Recaptcha
              className={props.recaptchaClassName}
              onChange={props.recaptchaOnChange}
              elementId={props.recaptchaElementId}
              errors={errors[props.name]}
              touched={touched}
              isInvalid={
                props.validationError ||
                (touched[props.name] && !!errors[props.name])
              }
            />
          )}
          {(combinedErrors[props.name] || errors[props.name]) && !props.group_name && (
            <Form.Control.Feedback type="invalid">
              {combinedErrors[props.name] || errors[props.name]}
            </Form.Control.Feedback>
          )}
          {props.helpText && (
            <Form.Text
              className={`${props.helpTextClass} help-text`}
              id={props.aria_describedby}
              muted={props.muted}
            >
              {props.helpTextClass === 'required' && <span className='required helptext-required'>⋆</span>}
              {props.helpText && (
                <ReactMarkdown linkTarget={"_blank"}>
                  {props.helpText}
                </ReactMarkdown>
              )}
            </Form.Text>
          )}
        </Form.Group>
      )}
    </>
  );
}


export default FormInput;
