import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { accountFields } from "../../stories/Forms/fields";
import useApis from "../../services/useAPI";
import DisplayItems from "../TestDetails/DisplayItems";
import ModalForm from "../../stories/Modal/ModalForm";
import { countriesUrl, editAccountDetailsUrl } from "../../utils/constants";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { BoxArrowInRight, XSquare } from "react-bootstrap-icons";
import { editDetailsSchema } from "../../stories/Forms/validationSchema";

/**
 * Account component to display and edit user account details.
 * @returns {JSX.Element} A section for displaying account information with an option to edit.
 */

const Account = () => {
  const auth = useAuth();
  const [accountDetails, setAccountDetails] = useState({});
  const [countries, setCountries] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const userId = localStorage.getItem("user_id");
  const { callApi, resultGet, resultPost, isLoading, serverError } = useApis();

  // Fetches account details
  useEffect(() => {
    const fetchAccountDetails = async () => {
      await callApi(null, editAccountDetailsUrl(userId), "GET");
    };
    fetchAccountDetails();
  }, [userId]);

  // Fetches list of countries
  useEffect(() => {
    const fetchCountries = async () => {
      await callApi(null, countriesUrl, "GET");
    };
    fetchCountries();
  }, []);

  // Handles results from API calls
  useEffect(() => {
    if (resultGet?.data) {
      if (resultGet.message == "user details retrived") {
        setAccountDetails(resultGet.data);
      }
    } else if (resultGet) {
      setCountries(resultGet);
    }
  }, [resultGet]);

  // Shows toaster messages based on API result
  useEffect(() => {
    if (resultPost?.data) {
      auth?.showToast(
        "Success",
        resultPost.message,
        "../assets/mhc.png",
        <BoxArrowInRight />
      );
      setModalShow(false);
      callApi(null, editAccountDetailsUrl(userId), "GET");
    }
    else if (serverError) {
      auth?.showToast(
        "Error",
        "error while submitting account details",
        "../assets/mhc.png",
        <XSquare />);
    }
  }, [resultPost]);

  // Filters fields for DisplayItems
  const displayFields = accountFields.filter(
    field => field.visibleInDisplay !== false
  );

  // Form submission handler
  const handleSubmit = async values => {
    // Converts empty strings to null for specific fields
    const formattedValues = {
      ...values,
      phone_number_secondary: values?.phone_number_secondary?.trim() === "" ? null : values.phone_number_secondary,
      home_postal_code: values?.home_postal_code?.trim() === "" ? null : values.home_postal_code,
      home_city: values?.home_city?.trim() === "" ? null : values.home_city,
      home_street_address: values?.home_street_address?.trim() === "" ? null : values.home_street_address,
      passport_number: values?.passport_number?.trim() === "" ? null : values.passport_number,
    };

    await callApi(
      formattedValues,
      `/api/dashboard/edit-account-details/${userId}`,
      "PUT"
    );
  };

  const handleCancel = () => {
    setModalShow(false);
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );

  }
  if (serverError) return <div>Error: {serverError}</div>;

  return (
    <>
      <div className="row justify-content-center account-page">
        <div className="col-md-12">
          <div className="d-flex align-items-center mb-4">
            <h1>My account</h1>
            <Button
              variant="primary"
              className="ml-4 mb-2 px-4 justify-content-center ms-3 edit-modal-btn"
              onClick={() => setModalShow(true)}
            >
              <div className="d-flex">
                <FontAwesomeIcon icon={faEdit} className="me-2" />
                <span className="mt-1  lh-1">Edit</span>
              </div>
            </Button>
          </div>

          <p className="text-muted text-md-left">
            Set your login preferences, help us personalize your experience, and
            make account changes here.
          </p>

          {accountDetails && (
            <DisplayItems fields={displayFields} data={accountDetails} />
          )}
        </div>

        <ModalForm
          show={modalShow}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          initialValues={accountDetails}
          onHide={() => setModalShow(false)}
          genderButton={true}
          countries={countries}
          error={serverError}
          validationSchema={editDetailsSchema}
        />
      </div>
    </>
  );
};

export default Account;
