import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getTestTypeValueFromUrl, getTestName } from "../../utils";
import { phlebotomyAssessmentStatusUrl } from "../../utils/constants";
import useApis from "../../services/useAPI";
import { Button } from "../../stories/Buttons/Button";
import { Alert, Modal } from "react-bootstrap";
import { useAuth } from "../../components/AuthContext/AuthContext";


/**
 * SuccessPage component
 * Displays the success message after completing a registration for the phlebotomy test.
 * It includes options to view or edit registration details, and an option to cancel the registration.
 *
 * @returns {JSX.Element} The rendered page displaying confirmation, booking details, and cancellation options.
 */

const SuccessPage = () => {
  const [details, setDetails] = useState(null);
  const { test_type, bid } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const { callApi, resultGet, resultPost } = useApis();
  const navigate = useNavigate();
  const auth = useAuth();

  // Toggles modal visibility
  const toggleModal = () => setModalOpen(!modalOpen);

  // Handles the API call to reject a booking
  const rejectBooking = async () => {
    try {
      await callApi(null, `/api/dashboard/reject-booking/${bid}/`, "POST");
    } catch (error) {
      console.error("Error rejecting booking", error);
      auth?.showToast(
        "Reject Booking",
        "An error occurred while rejecting the booking.",
        "../assets/mhc.png"
      );
    }
  };

  // Handles post-rejection behavior
  useEffect(() => {
    if (resultPost) {
      const message =
        resultPost?.status === "rejected"
          ? "Your appointment has been rejected successfully. You can proceed to book a new one now."
          : "Reject failed";
      auth?.showToast("Reject Booking", message, "../assets/mhc.png");
      if (resultPost?.status === "rejected") {
        navigate(`/bloods/boots/${test_type || localStorage.getItem("test_type")}?rejected=true`);
      }
    }
  }, [resultPost, auth, navigate, test_type]);

  // Opens the modal for confirmation
  const handleRejectBooking = () => toggleModal();

  //Handles reject confirmation
  const handleConfirmReject = () => {
    rejectBooking();
    toggleModal();
  };

  // Fetches booking details when the component loads
  useEffect(() => {
    const fetchDetails = async () => {
      await callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
    };
    fetchDetails();
  }, [bid]);

  // Updates the state with fetched booking details
  useEffect(() => {
    if (resultGet) {
      setDetails(resultGet?.phlebotomist_booking_info);
    }
  }, [resultGet]);

  // Navigates to the details page based on the booking status
  const viewEditDetails = () => {
    navigate(`/bloods/boots/assessment-details/${getTestTypeValueFromUrl(details?.test_type)}/${bid}`);
  };

  return (

    <div className="row justify-content-center dashboard-page">
      <div className="col-md-12">
        {details ? (
          <>
            <h1 className="mb-4">APPOINTMENT CONFIRMATION</h1>
            <div className="row justify-content-center py-5">
              <div className="col-md-12">
                <h3>Success!</h3>
                <p>
                  Your registration with Boots In-store Wellness Testing Service powered by MyHealthChecked is now complete.
                </p>
                <hr />
                <h3>
                  Test type: {getTestName(getTestTypeValueFromUrl(details.test_type))}
                </h3>
                <p>
                  When you arrive for your appointment, please provide the health professional with registration number{" "}
                  <strong>
                    {details?.booking_status === "archived"
                      ? details?.booking_id + "-2"
                      : details?.booking_id}
                  </strong>.
                </p>
                <p>You can edit your registration details until the day of your appointment.</p>
                <Button
                  className="btn-start-assessment"
                  onClick={viewEditDetails}
                  label={resultGet?.can_be_edited ? "Edit details" : "View details"}
                />
                {details?.booking_status !== 'rejected' && details?.booking_status !== 'completed' && <Alert className="mt-4 text-justify" variant="warning">
                  <strong>Important Reminder: </strong>
                  <p>
                    You can only register for one test at a time. Once you’ve received your test results, you’ll be able to
                    register for another.
                  </p>
                  <p>If you'd like to cancel your current registration, please select “Cancel” below.</p>
                  <span>
                    <Button className="btn-cancel" label="Cancel registration" onClick={handleRejectBooking} />
                  </span>
                </Alert>}
              </div>
            </div>
          </>

        ) : (
          null
        )}
        <Modal show={modalOpen} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Rejection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to reject this appointment? This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-grey" onClick={toggleModal} label="Cancel" />
            <Button className="btn-reject" onClick={handleConfirmReject} label="Confirm" />
          </Modal.Footer>
        </Modal>
      </div>
    </div>

  );
};

export default SuccessPage;