import React from "react";
import { Container } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container>
    <h1>Page not found</h1>
      <h4 className="mt-4">The page requested by you does not exist.</h4>
    </Container>
  );
};

export default NotFound;
