import React, { useState, useEffect } from "react";
import { LinkCard } from "../../stories/cards/LinkCard";
import Boots from "../../assets/images/Vector.svg";
import ContinueJourney from "./ContinueJourney";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useApis from "../../services/useAPI";
import { summaryUrl, bookingUrl } from "../../utils/constants";
import { getTestTypeValueFromUrl } from "../../utils";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { InfoCircle } from "react-bootstrap-icons";
import NotFound from "../../components/NotFound";

/**
 * PhlebotomyDashboard component
 * Displays the user's phlebotomy test journey and handles navigation between various states of the booking process.
 *
 * @returns {JSX.Element} The rendered dashboard component, with either a booking option or a continue journey option.
 */

const PhlebotomyDashboard = (props) => {
  const { test_type } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const [untestable, setUntestable] = useState();
  const [resume, setResume] = useState();
  const [allow_booking, setAllowBooking] = useState();
  const [rejected, setRejected] = useState(false);

  const { callApi, resultGet } = useApis();

  // Fetches data for pending journey if any
  const fetchSummary = () => {
    callApi(null, summaryUrl, "GET");
  };

  // Sets the booking rejection state.
  const isBookingRejected = () => {
    setRejected(true);
  }

  // Fetches booking details if the user is authenticated.
  const fetchBookings = () => {
    callApi(null, bookingUrl, "GET");
  };

  // Updates state based on the API response.
  useEffect(() => {

    if (resultGet?.bookings) {
      setAllowBooking(resultGet.allow_booking);
      setResume(resultGet.bookings ? resultGet.bookings[0] : null);
    }
    else if (resultGet?.latest_test) {
      setUntestable(resultGet.latest_test?.lab_failed);
    }
  }, [resultGet, allow_booking]);

  // Fetches bookings when the access token or rejection state changes.
  useEffect(() => {
    fetchBookings();
  }, [rejected]);

  // Fetches summary if the user has an incomplete journey.
  useEffect(() => {
    if (resume?.registration_stage === 'assessment' || resume?.registration_stage === 'edit-details') {
      fetchSummary();
    }
  }, [resume]);

  // Navigates based on the booking or test status.
  useEffect(() => {
    if (props?.elementProps && resume?.registration_stage === 'assessment' || resume?.registration_stage === 'edit-details') {
      if (!allow_booking && !untestable && resume.booking_status !== 'completed') {
        navigate(`/bloods/boots/success-page/${getTestTypeValueFromUrl(test_type)}/${resume?.id}`)
      } else if (!allow_booking) {
        auth?.showToast(
          "You have a test currently in process",
          "Once you receive the results for your on going (Phlebotomy service) test, you'll be able to book a new one.",
          "../assets/mhc.png",
          <InfoCircle />
        );
        navigate("/tests")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resume, allow_booking, untestable, rejected])

  // Checks the URL for any rejection status query parameter.
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("rejected") === "true") {
      setRejected(true);
    }
  }, [location.search]);

  return (
    <div className="row justify-content-center dashboard-page">
      {props.elementProps ? (
        <div className="col-md-12">
          <div className="dashboard">
            {allow_booking ? (
              <>
                <h1 className="mb-4">MY REGISTRATION</h1>
                <div className="pt-sm-4 mt-4 mb-4">
                  <div className="container activate-box-wrapper">
                    <div className="row">
                      <div className="col-md-12">
                        <LinkCard
                          to={`/bloods/boots/registration/${getTestTypeValueFromUrl(test_type)}`}
                          image={Boots}
                          title="Register your Boots store appointment"
                          button_text="Continue"
                          button_append_class="my-button-class"
                        >
                          <div className="text-muted">
                            Boots In-store Wellness Testing Service powered by MyHealthChecked
                          </div>
                        </LinkCard>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <ContinueJourney
                untestable={untestable}
                resume={resume}
                isBookingRejected={isBookingRejected}
                test_type={
                  getTestTypeValueFromUrl(test_type) !== 'unknown'
                    ? getTestTypeValueFromUrl(test_type)
                    : resume?.test_type
                }
              />
            )}
          </div>
        </div>
      ) : (
        <NotFound />
      )}

    </div>
  );
};

export default PhlebotomyDashboard;