import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../views/Account/LoginPage";
import ResetPassword from "../views/Account/ResetPassword";
import SetPassword from "../views/Account/SetPassword";
import Dashboard from "../views/Dashboard/Dashboard";
import CreateAccount from "../views/Account/CreateAccount";
import ConfirmAccount from "../views/Account/ConfirmAccount";
import TestList from "../views/TestsList";
import TestKitDetails from "../views/TestDetails/TestKitDetails";
import EnterBarcode from "../views/Activation/EnterBarcode";
import ConfirmDetails from "../views/Activation/ConfirmDetails";
import { Consent } from "../views/Activation/Consent";
import SecondaryBarcode from "../views/Activation/SecondaryBarcode";
import EssenTialTips from "../views/Activation/EssentialTips";
import TimeAdministered from "../views/Activation/TimeAdministered";
import ShConsent from "../views/Activation/ShConsent";
import WellnessCollectSample from "../views/Activation/WellnessCollectSample";
import BloodReportPage from "../views/Bloods/Reports/BloodReportPage";
import { BloodMarkerModal } from "../views/Bloods/Reports/BloodMarkerModal";
import PrivateRoute from "../components/AuthContext/PrivateRoute";
import Account from "../views/Account/Account";
import ResumeActivateBlood from "../views/Activation/ResumeActivateBlood";
import WellnessReportPage from "../views/WellnessReports/WellnessReportPage";
import PhlebotomyDashboard from "../views/Phlebotomy/PhlebotomyDashboard";
import ConfirmTestType from "../views/Phlebotomy/ConfirmTestType";
import PhlebotomyStartAssessment from "../views/Phlebotomy/PhlebotomyStartAssessment";
import SuccessPage from "../views/Phlebotomy/SuccessPage";
import { SimpleAssessmentSummary } from "../views/Health Assessment/DetailsPage";
import WellnessAssessment from "../views/Health Assessment/WellnessAssessment";
import WellnessStart from "../views/Health Assessment/WellnessStart";
import EnableMFA from "../views/Account/EnableMFA";
import ConfirmOtp from "../views/Account/ConfirmOtp";
import VerifyOTP from "../views/Account/VerifyOTP";
import StartPage from "../views/Health Assessment/StartPage";
import BodyForm from "../views/Health Assessment/BodyForm"
import ExerciseForm from "../views/Health Assessment/ExerciseForm";
import MedicalConditions from "../views/Health Assessment/MedicalConditions";
import Medications from "../views/Health Assessment/Medications";
import SexualHealth from "../views/Health Assessment/SexualHealth";
import FinalStep from "../views/Health Assessment/FinalStep";
import ActivateTestKit from "../views/Activation/ActivateTestKit";
import NotFound from "../components/NotFound"

export default ({isHcpLive}) => {
  return (
    <Routes>
      <Route element={<PrivateRoute element={Dashboard} />} path="/" />
      <Route
        element={<PrivateRoute element={ActivateTestKit} />}
        path="/activate"
      />
      <Route element={<LoginPage />} path="/login" />
      <Route element={<EnableMFA />} path="/enable-mfa" />
      <Route element={<ConfirmOtp />} path="/confirm-otp" />
      <Route element={<VerifyOTP />} path="/verify-otp" />
      <Route element={<ResetPassword />} path="/reset-password" />
      <Route element={<SetPassword />} path="/reset-password/:token/:id" />
      <Route element={<CreateAccount />} path="/create-account" />
      <Route element={<ConfirmAccount />} path="/create-account/:id" />

      {/* MyResult Routes */}
      <Route element={<PrivateRoute element={TestList} />} path="/tests" />
      <Route
        element={<PrivateRoute element={TestKitDetails} />}
        path="/test-kits/:test_kit_id/"
      />

      {/* Return to My Activation routes */}
      <Route
        element={<PrivateRoute element={ResumeActivateBlood} />}
        path="/test-kits/resume/:test_kit_id/"
      />

      {/* Activation routes */}
      <Route
        element={<PrivateRoute element={ActivateTestKit} />}
        path="/activate"
      />
      <Route
        element={<PrivateRoute element={EnterBarcode} />}
        path="/tests/bloods/activate"
      />
      <Route
        element={<PrivateRoute element={EnterBarcode} />}
        path="/tests/sh/activate"
      />
      <Route
        element={<PrivateRoute element={EnterBarcode} />}
        path="/tests/dna/activate"
      />
      <Route
        element={<PrivateRoute element={ConfirmDetails} />}
        path="/confirm-details/:primaryBarcode"
      />
      <Route
        element={<PrivateRoute element={Consent} />}
        path="/consent/:primaryBarcode"
      />
      <Route
        element={<PrivateRoute element={SecondaryBarcode} />}
        path="/secondary-barcode/:primaryBarcode"
      />
      <Route
        element={<PrivateRoute element={EssenTialTips} />}
        path="/essential-tips/:primaryBarcode"
      />
      <Route
        element={<PrivateRoute element={TimeAdministered} />}
        path="/time-administered/:primaryBarcode"
      />
      <Route element={<PrivateRoute element={SimpleAssessmentSummary} />} path="/assessment-details/:test_kit_id" />
      <Route element={<PrivateRoute element={StartPage} />} path="/assessment/:test_kit_id/1" />
      <Route element={<PrivateRoute element={BodyForm} />} path="/assessment/:test_kit_id/2" />
      <Route element={<PrivateRoute element={ExerciseForm} />} path="/assessment/:test_kit_id/3" />
      <Route element={<PrivateRoute element={MedicalConditions} />} path="/assessment/:test_kit_id/4" />
      <Route element={<PrivateRoute element={Medications} />} path="/assessment/:test_kit_id/5" />
      <Route element={<PrivateRoute element={SexualHealth} />} path="/assessment/:test_kit_id/6" />
      <Route element={<PrivateRoute element={FinalStep} />} path="/assessment/:test_kit_id/7" />
      <Route
        element={<PrivateRoute element={ShConsent} />}
        path="/sh-consent/:primaryBarcode"
      />
      <Route
        element={<PrivateRoute element={WellnessCollectSample} />}
        path="/wellness-collect-sample/:primaryBarcode"
      />

      {/* Reports routes */}
      <Route
        element={<PrivateRoute element={BloodReportPage} />}
        path="/my-reports/blood/:test_kit_id"
      />
      <Route
        element={<PrivateRoute element={BloodMarkerModal} />}
        path="/bloods-modal"
      />
      <Route
        element={<PrivateRoute element={WellnessReportPage} />}
        path="/wellness-report/:id"
      />

      {/* MyAccount routes */}
      <Route element={<PrivateRoute element={Account} />} path="/account" />

      {/* Phlebotomy routes */}
      <Route
        element={<PrivateRoute element={PhlebotomyDashboard} elementProps={isHcpLive} />}
        path="/bloods/boots/:test_type"
      />
      <Route
        element={<PrivateRoute element={ConfirmTestType} />}
        path="/bloods/boots/registration/:test_type"
      />
      <Route
        element={<PrivateRoute element={Consent} />}
        path="/bloods/boots/:test_type/booking/consent/:bid"
      />
      <Route
        element={<PrivateRoute element={ConfirmDetails} />}
        path="/bloods/boots/:test_type/confirm-details/:bid"
      />
      <Route
        element={<PrivateRoute element={PhlebotomyStartAssessment} />}
        path="/bloods/boots/start-assessment/:test_type/:bid"
      />
      <Route
        element={<PrivateRoute element={StartPage} />}
        path="/bloods/boots/assessment/:test_type/:bid/1"
      />
      <Route element={<PrivateRoute element={BodyForm} />} path="/bloods/boots/assessment/:test_type/:bid/2" />
      <Route element={<PrivateRoute element={ExerciseForm} />} path="/bloods/boots/assessment/:test_type/:bid/3" />
      <Route element={<PrivateRoute element={MedicalConditions} />} path="/bloods/boots/assessment/:test_type/:bid/4" />
      <Route element={<PrivateRoute element={Medications} />} path="/bloods/boots/assessment/:test_type/:bid/5" />
      <Route element={<PrivateRoute element={SexualHealth} />} path="/bloods/boots/assessment/:test_type/:bid/6" />
      <Route element={<PrivateRoute element={FinalStep} />} path="/bloods/boots/assessment/:test_type/:bid/7" />
      <Route
        element={<PrivateRoute element={SimpleAssessmentSummary} />}
        path="/bloods/boots/assessment-details/:test_type/:bid"
      />
      <Route
        element={<PrivateRoute element={SuccessPage} />}
        path="/bloods/boots/success-page/:test_type/:bid"
      />

      {/* Health assessment routes */}
      <Route
        element={<PrivateRoute element={WellnessAssessment} />}
        path="/wellness-assessment/:test_kit_id"
      />
      <Route
        element={<PrivateRoute element={WellnessStart} />}
        path="/assessment/wellness/:test_kit_id"
      />

      <Route element={<NotFound />} path="*" />

    </Routes>
  );
};
