import React, { useEffect, useState } from "react";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BloodMarkerCard } from "./BloodMarkerCard";
import "../../../assets/scss/_health-area-section.scss";
import { iconHelper } from "../../../utils/utils";
import { Collapse } from "react-bootstrap";

/**
 * Get the status label based on the health area status.
 *
 * @param {string} status - The status of the health area.
 * @returns {string} The status label.
 */
const getStatusLabel = status => {
  if (status === "good") return "Everything looks good";
  if (status === "require-attention") return "Some results require attention";
  return "Some borderline results";
};

/**
 * Determine the sexual health status based on markers.
 *
 * @param {Array} markers - The array of markers to evaluate.
 * @returns {string} The status of sexual health.
 */

export const getStatusSexualHealth = markers => {
  let badCount = 0;
  let existUnconfirmedReactive = false;

  markers.forEach(marker => {
    if (["normal", "green"].indexOf(marker.result) === -1) {
      badCount++;
    }

    if (String(marker.value).toLowerCase() === "unconfirmed reactive") {
      existUnconfirmedReactive = true;
    }
  });

  if (existUnconfirmedReactive) {
    return "retest";
  }

  return badCount > 0 ? "attention" : "normal";
};

/**
 * HealthAreaSection component for displaying health area information.
 * @returns {JSX.Element} The rendered health area section.
 */

const HealthAreaSection = ({
  area,
  area_status,
  doctors_notes,
  markers,
  hideHeader,
  hideTitle,
  hideIcon,
  collapseItems,
  type
}) => {
  const [allMarkersVisible, setMarkersVisibility] = useState(false);
  const [healthArea, setHealthArea] = useState("");
  useEffect(() => {
    setHealthArea(area_status);
  }, []);

  // Sort markers alphabetically
  markers.sort((a, b) => a.name.localeCompare(b.name));
  const renderHeader = () => {
    const isSexualHealth = type?.includes("sex-health");
    const icon = iconHelper(`Biomarker ${area}`);
    let statusIcon = area_status === "good" ? faCheck : faExclamation;
    let statusLabel = getStatusLabel(area_status);

    if (isSexualHealth) {
      const testStatus = getStatusSexualHealth(markers);

      const groupStatusComment = {
        normal:
          "This test has not detected any presence of infection. Please continue to practice safe sex",
        attention:
          "This test has detected the presence of an infection in your urine sample. This is a very treatable infection. You will require a course of medication from your GP. Early treatment is beneficial. Please do not delay",
        retest:
          "This test has been unable to confirm the presence of an infection in your blood sample. You will require a venous blood sample to investigate. You must attend a sexual health clinic or speak to your GP to arrange a venous sample to be taken. It is very easy to pass STIs to sexual partners and you must abstain from sexual contact until further investigations are complete."
      }[testStatus];

      return (
        <header className="healthArea__header">
          <div className="healthArea__header-content">
            <h2 className="healthArea__header-title">
              Your test results
              <span
                className={`healthArea__header-status healthArea__header-status--${testStatus}`}
              >
                <FontAwesomeIcon icon={statusIcon} />
              </span>
            </h2>
            <p className="healthArea__header-subtitle">
              <span className="healthArea__header-subtitle-label-sexual-health">
                {statusLabel}
              </span>
            </p>
            <p>{groupStatusComment}</p>
          </div>
        </header>
      );
    }

    if (type?.includes("sex-health")) {
      const testStatus = getStatusSexualHealth(markers);
      statusIcon = testStatus === "good" ? faCheck : faExclamation;
    }

    if (hideHeader) return null;

    return (
      <header className="healthArea__header">
        {icon && !hideIcon && (
          <img src={icon} alt={area} className="healthArea__header-icon" />
        )}
        <div className="healthArea__header-content">
          {!hideTitle && (
            <h2 className="healthArea__header-title">
              {area}{" "}
              <span
                className={`healthArea__header-status healthArea__header-status--${area_status}`}
              >
                <FontAwesomeIcon icon={statusIcon} />
              </span>
            </h2>
          )}
          <p
            className={`healthArea__header-subtitle healthArea__header-subtitle--${area_status}`}
          >
            <span>{getStatusLabel(area_status)}</span>
          </p>
          {doctors_notes && <p>{doctors_notes}</p>}
        </div>
      </header>
    );
  };

  let firstMarkers =
    collapseItems && markers?.length ? markers.slice(0, 3) : markers;
  if (!firstMarkers?.length) firstMarkers = [];
  const otherMarkers = markers?.length ? markers.slice(3) : [];

  return (
    <section className="healthArea__container">
      {renderHeader()}

      {/* Render list of markers */}
      <div className="healthArea__list">
        {firstMarkers.map(marker => (
          <BloodMarkerCard key={marker.code} marker={marker} />
        ))}
        <Collapse in={Boolean(allMarkersVisible)}>
          <div>
            {Array.isArray(otherMarkers) &&
              otherMarkers.map(marker => {
                return marker ? (
                  <BloodMarkerCard key={marker.code} marker={marker} />
                ) : null;
              })}
          </div>
        </Collapse>
        {collapseItems && otherMarkers.length > 0 && (
          <button
            className="healthArea__button"
            onClick={() => setMarkersVisibility(!allMarkersVisible)}
          >
            {allMarkersVisible
              ? "Show less"
              : `Show all (${otherMarkers.length})`}
          </button>
        )}
      </div>
    </section>
  );
};

export default HealthAreaSection;
