// src/components/AppointmentInfo.jsx (optional file if you want to separate it)
import React, { useEffect, useCallback } from "react";
import { Alert, Button, Container, Spinner } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import Calendly from "./Calendly";
import { useAuth } from "../../components/AuthContext/AuthContext";

export function AppointmentInfo({
  user_time,
  note,
  notes,
  meet_details,
  show_button,
  setShowButton,
  event_status,
  test_id,
  meeting_time,
  updatePostData,
  meetingDetails,
  displayCalendly,
  setDisplayCalendly
}) {

  const auth = useAuth()
  const handleEdit = useCallback(async () => {
    setDisplayCalendly('p-2');
  }, [displayCalendly, show_button]);

  useEffect(() => {
    if (note?.includes("You have reached the maximum number of reschedule attempts")) {
      setShowButton(false);
    }
  }, [displayCalendly, auth.editReload]);

  return (
    <div>
      <p>
        Thank you for scheduling your appointment to speak with one of our
        doctors.
        <span className={event_status === "reschedule_2" ? "d-none" : ""}>
          In the event that you need to reschedule your appointment, we kindly
          request that you use the button below to do so within{" "}
          <strong>24 hours</strong> prior to your appointment.
        </span>
      </p>

      <h3>Appointment details</h3>
      <>
        <strong>Date & time: &nbsp;</strong>
        {user_time === null || meet_details === null ? (
          <Spinner animation="border" />
        ) : (
          user_time || meet_details || meeting_time
        )}
      </>
      <br />
      <p>
        <strong>
          Our doctor will contact you at the telephone number you have provided
        </strong>
      </p>

      {
        (show_button) ? (
          event_status !== "reschedule_2" && (
            <Button variant="primary" className="m-2" onClick={handleEdit} >
              Edit appointment
            </Button>
          )
        ) : (
          event_status !== "reschedule_2" && (
            <Button variant="primary" onClick={handleEdit} disabled>
              Edit appointment
            </Button>
          )
        )
      }

      {(note || notes) && (
        <Alert className="m-2 rounded notes-alert" variant="warning">
          <ReactMarkdown>{notes || note}</ReactMarkdown>
        </Alert>
      )}

      <Container className={displayCalendly}>
        <Calendly
          id={test_id}
          event_status={event_status}
          displayCalendly={displayCalendly}
          updatePostData={updatePostData}
          meetingDetails={meetingDetails}
        />
      </Container>
      <br />
      <br />
    </div>
  );
}
