import React, { useEffect, useState } from "react";
import { useAuth } from '../../components/AuthContext/AuthContext'
import { medicalConditionFields } from '../../stories/Forms/fields';
import { medicalConditionsSchema } from '../../stories/Forms/validationSchema';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";
import {
  assessmentStatusUrl,
  assessmentValidateUrl,
  phlebotomyAssessmentStatusUrl,
} from "../../utils/constants";
import CheckboxGroupForm from "../../stories/Forms/CheckboxFormGroup";
import { XSquare } from "react-bootstrap-icons";

let submissionData;

/**
 * MedicalConditions Component
 * Handles the "Medical Conditions" step of the health assessment process.
 * Displays a form with checkboxes to capture medical condition information.
 * Validates user input and processes submission data.
 */

function MedicalConditions() {
  const auth = useAuth()
  const { bid, test_type } = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const formId = location.pathname.split('/').at(-1)
  const { setCompletedSteps, setIsCompleted, setStep } = auth;
  const [is_assessment_completed, set_Is_assessment_completed] = useState(false);
  const { test_kit_id } = useParams();

  const [data, setData] = useState({});

  const isPhlebotomy = location.pathname.includes('boots');

  const { callApi, resultGet, resultPost, serverError } = useApis();

  const handleAssessmentSubmit = (values) => {

    // Remove keys with undefined values
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });

    const medicalConditions =
      values?.medical_conditions.conditions.condition || auth?.allStepData?.medical_conditions?.condition || [];
    const hasOtherCondition = medicalConditions?.includes("Other") || [];
    const otherDescription =
      values?.medical_conditions?.other_description || auth?.allStepData?.medical_conditions?.other_description || "";

    submissionData = {
      medical_conditions: {
        condition: values.medical_conditions?.conditions?.was_diagnosed === 'Yes' ? medicalConditions : [],
        other_description: hasOtherCondition ? otherDescription : "",
      },
      step: "medical_conditions",
    };

    if (submissionData) {
      callApi(
        {
          user_id: JSON.parse(localStorage.getItem("user_id")),
          ...submissionData
        },
        `${assessmentValidateUrl}`,
        "POST",
        true
      );
    }
    setData(submissionData)
    auth.setAllStepData((prevStep) => ({ ...prevStep, ...submissionData }));

    setIsCompleted(parseInt(formId) + 1); // Increment the step based on the latest value
    setCompletedSteps(prevSteps => prevSteps + 1); // Use functional update to access the latest value
    setStep(prevStep => prevStep + 1);
  }
  useEffect(() => {
    if (resultGet) {

      auth.setTotalSteps(resultGet?.list_of_steps?.includes('phlebotomist_booking_info') ? 6 : 7)

      const medicalConditions =
        resultGet?.assessment_values?.medical_conditions?.condition || auth?.allStepData?.medical_conditions?.condition;
      const hasOtherCondition = medicalConditions?.includes("Other");
      const otherDescription =
        resultGet?.assessment_values?.medical_conditions?.other_description || auth?.allStepData?.medical_conditions?.other_description || "";

      submissionData = {
        medical_conditions: {
          condition: medicalConditions,
          other_description: hasOtherCondition ? otherDescription : "",
        },
        step: "medical_conditions",
      };
      setData(submissionData)
    }
  }, [resultGet])

  useEffect(() => {

    if (resultPost) {
      setIsCompleted(parseInt(formId)); // Increment the step based on the latest value
      setCompletedSteps(parseInt(formId) + 1); // Use functional update to access the latest value
      setStep(parseInt(formId));
      if (isPhlebotomy && test_type && bid) {
        navigate(`/bloods/boots/assessment/${test_type}/${bid}/${parseInt(formId) + 1}`, { state: { ...auth.allStepData } })
      } else {
        navigate(`/assessment/${test_kit_id}/${parseInt(formId) + 1}`, { state: { ...auth.allStepData } })
      }
    }
    if (isPhlebotomy && test_type && bid) {
      callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
    }
    else if (!isPhlebotomy && !test_type && !bid) {
      location?.state?.test_kit_id &&
        localStorage.setItem("test_kit_id", location?.state?.test_kit_id);
      callApi(
        null,
        `${assessmentStatusUrl}${test_kit_id ||
        localStorage.getItem("test_kit_id")}`,
        "GET",
        true
      );
    }
  }, [auth.step, auth.completedSteps, auth.editReload, resultPost]);


  useEffect(() => {
    set_Is_assessment_completed(resultGet?.is_assessment_completed)
    if (serverError) {
      auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
    }
  }, [resultGet, serverError])

  return (
    <div className="row justify-content-center start-assessment-page">
      <div className="col-md-12">
        <StepProgressBar
          steps={auth.totalSteps}
          currentStep={4}
          completedSteps={3}
          isCompleted={3}
          is_assessment_completed={is_assessment_completed}
        />
        <div className="container-fluid">
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="mt-5">
                <h1 className="mb-4">Medical conditions</h1>
                <CheckboxGroupForm
                  className="assessment-form"
                  fields={medicalConditionFields}
                  blockRefresh={true}
                  initialValues={{ ...data?.medical_conditions }}
                  type={"medical_conditions"}
                  is_assessment_completed={resultGet?.is_assessment_completed}
                  validationSchema={medicalConditionsSchema}
                  onSubmit={handleAssessmentSubmit}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default MedicalConditions
