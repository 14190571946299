import React, { useEffect, useState } from "react";
import { useAuth } from '../../components/AuthContext/AuthContext'
import { sexualHealthFields, menstrualCycleFields } from '../../stories/Forms/fields';
import { shSchema, menstrualSchema } from '../../stories/Forms/validationSchema';
import { Row, Col, Spinner } from 'react-bootstrap';
import DynamicForm from '../../stories/Forms/DynamicForm';
import {
  assessmentStatusUrl,
  assessmentValidateUrl,
  phlebotomyAssessmentStatusUrl,
} from "../../utils/constants";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";
import { XSquare } from "react-bootstrap-icons";

let submissionData;

/**
 * Component to handle Sexual Health and Menstrual Cycle assessments.
 * Renders a dynamic form based on the type of test (sexual health or hormone-related).
 */

function SexualHealth() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { test_kit_id } = useParams();;
  const [isShTest, setIsShTest] = useState();
  const [isHormoneTest, setIsHormoneTest] = useState();
  const [data, setData] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [is_assessment_completed, set_Is_assessment_completed] = useState(false);
  const { bid, test_type } = useParams();
  const formId = location.pathname.split('/').at(-1)
  const { setCompletedSteps, setIsCompleted, setStep } = auth;

  const isPhlebotomy = location.pathname.includes('boots');

  const { callApi, resultGet, resultPost, serverError } = useApis();

  const handleAssessmentSubmit = (values) => {
    // Remove keys with undefined values
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });

    if (isShTest) {
      submissionData = {
        sexual_health: {
          sexually_active: values?.sexually_active || "",
          partners: values?.partners || "",
          recent_sex: values?.recent_sex || "",
          had_unprotected_sex: values?.had_unprotected_sex || "",
          unprotected_sex_type: values?.had_unprotected_sex === 'No' ? [] : values?.unprotected_sex_type || [],
          sti_diagnosed: values?.sti_diagnosed || "",
          sti_symptoms: values?.sti_symptoms || "",
          sti_symptoms_description:
            values?.sti_symptoms_description || "",
          potential_sti_exposure: values?.potential_sti_exposure || "",
          potential_sti_exposure_type:
            values?.potential_sti_exposure_type === 'No' ? [] : values?.potential_sti_exposure_type || [],
        },
        step: "sexual_health",
      };
    } else if (isHormoneTest) {
      submissionData = {
        menstrual: {
          cycle_length: values?.cycle_length,
          day_of_cycle: values?.day_of_cycle,
          contraception: values?.contraception,
        },
        step: "menstrual",
      };
    }
    if (submissionData) {
      callApi(
        {
          user_id: JSON.parse(localStorage.getItem("user_id")),
          ...submissionData
        },
        `${assessmentValidateUrl}`,
        "POST",
        true
      );
    }
    setData(submissionData)
    auth.setAllStepData((prevStep) => ({ ...prevStep, ...submissionData }));

    setIsCompleted(parseInt(formId) + 1); // Increment the step based on the latest value
    setCompletedSteps(prevSteps => prevSteps + 1); // Use functional update to access the latest value
    setStep(prevStep => prevStep + 1);
  }

  useEffect(() => {
    if (isPhlebotomy && test_type && bid) {
      callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
    }
    else if (!isPhlebotomy && !test_type && !bid) {
      test_kit_id &&
        localStorage.setItem("test_kit_id", location?.state?.test_kit_id);
      callApi(
        null,
        `${assessmentStatusUrl}${test_kit_id}`,
        "GET",
        true
      );
    }
  }, [])

  useEffect(() => {
    if (resultGet?.test_kit_details?.test_kit_type?.includes('sex')) {
      setIsShTest(true)
      setIsHormoneTest(false)
    }
    else if (resultGet?.test_kit_details?.test_kit_type?.includes('menopause') || resultGet?.test_kit_details?.test_kit_type?.includes('polycystic')) {
      setIsHormoneTest(true)
      setIsShTest(false)
    }
  }, [resultGet]);

  useEffect(() => {
    // Show spinner if both isShTest and isHormoneTest are false
    if (!isShTest && !isHormoneTest) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false); // Hide spinner once one of them is true
    }
  }, [isShTest, isHormoneTest]);

  useEffect(() => {
    if (resultGet) {
      if (isShTest) {
        submissionData = {
          sexual_health: {
            sexually_active: resultGet?.assessment_values?.sexual_health?.sexually_active || auth.allStepData?.sexual_health?.sexually_active || "",
            partners: resultGet?.assessment_values?.sexual_health?.partners || auth.allStepData?.sexual_health?.partners || "",
            recent_sex: resultGet?.assessment_values?.sexual_health?.recent_sex || auth.allStepData?.sexual_health?.recent_sex || "",
            had_unprotected_sex: resultGet?.assessment_values?.sexual_health?.had_unprotected_sex || auth.allStepData?.sexual_health?.had_unprotected_sex || "",
            unprotected_sex_type: resultGet?.assessment_values?.sexual_health?.unprotected_sex_type || auth.allStepData?.sexual_health?.unprotected_sex_type || [],
            sti_diagnosed: resultGet?.assessment_values?.sexual_health?.sti_diagnosed || auth.allStepData?.sexual_health?.sti_diagnosed || "",
            sti_symptoms: resultGet?.assessment_values?.sexual_health?.sti_symptoms || auth.allStepData?.sexual_health?.sti_symptoms || "",
            sti_symptoms_description:
              resultGet?.assessment_values?.sexual_health?.sti_symptoms_description || auth.allStepData?.sexual_health?.sti_symptoms_description || "",
            potential_sti_exposure: resultGet?.assessment_values?.sexual_health?.potential_sti_exposure || auth.allStepData?.sexual_health?.potential_sti_exposure || "",
            potential_sti_exposure_type:
              resultGet?.assessment_values?.sexual_health?.potential_sti_exposure_type || auth.allStepData?.sexual_health?.potential_sti_exposure_type || [],
          },
          step: "sexual_health",
        };
      } else if (isHormoneTest) {
        submissionData = {
          menstrual: {
            cycle_length: resultGet?.assessment_values?.menstrual?.cycle_length || auth.allStepData?.menstrual?.cycle_length,
            day_of_cycle: resultGet?.assessment_values?.menstrual?.day_of_cycle || auth.allStepData?.menstrual?.day_of_cycle,
            contraception: resultGet?.assessment_values?.menstrual?.contraception || auth.allStepData?.menstrual?.contraception,
          },
          step: "menstrual",
        };
      }
      setData(submissionData)
    }
  }, [resultGet, isHormoneTest, isShTest])

  useEffect(() => {
    if (resultPost) {
      setIsCompleted(parseInt(formId)); // Increment the step based on the latest value
      setCompletedSteps(parseInt(formId) + 1); // Use functional update to access the latest value
      setStep(parseInt(formId));
      navigate(`/assessment/${test_kit_id}/${parseInt(formId) + 1}`, { state: { ...auth.allStepData } })
    }
  }, [auth.step, auth.completedSteps, resultPost]);

  useEffect(() => {
    set_Is_assessment_completed(resultGet?.is_assessment_completed)
    if (serverError) {
      auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
    }
  }, [resultGet, serverError])
  if (showSpinner) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );
  }

  return (
    <div className="row justify-content-center start-assessment-page">
      <div className="col-md-12">
        <StepProgressBar
          steps={auth.totalSteps}
          currentStep={6}
          completedSteps={5}
          isCompleted={5}
          is_assessment_completed={is_assessment_completed}
        />
        {(isShTest || isHormoneTest) && (
          <div className="container-fluid">
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="mt-5">
                  <h1 className="mb-4">
                    {isShTest ? "Sexual health" : "Menstrual cycle"}
                  </h1>
                  <div className="assessment-form">
                    <DynamicForm
                      fields={
                        isShTest
                          ? sexualHealthFields
                          : isHormoneTest && menstrualCycleFields
                      }
                      validationSchema={isShTest ? shSchema : menstrualSchema}
                      blockRefresh={true}
                      modify={true}
                      initialValues={
                        isShTest
                          ? (data?.sexual_health || { ...resultGet?.assessment_values?.sexual_health })
                          : (data?.menstrual || { ...resultGet?.assessment_values?.menstrual })
                      }
                      onSubmit={handleAssessmentSubmit}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );

}

export default SexualHealth
