import React from 'react';
import { Button } from '../../stories/Buttons/Button';
import { useParams } from 'react-router-dom';

/**
 * PhlebotomyStartAssessment component
 * Displays a message informing the user that they need to complete a short health assessment as part of their test activation process.
 * Provides a button to start the health assessment form.
 *
 * @returns {JSX.Element} The rendered component with a health assessment message and a button to begin the assessment.
 */

const PhlebotomyStartAssessment = () => {
    const { test_type } = useParams();
    const { bid } = useParams();
    return (
        <>
            <div className="row justify-content-center dashboard-page">
                <div className="col-md-12">
                    <div className="dashboard">
                        <h1>Health assessment</h1>
                        <div className="mt-4">
                            <p>You will now need to complete a short health assessment as part of your booking registration process</p>
                            <p>This will only take 2 minutes to complete.</p>
                            <Button
                                href={`/bloods/boots/assessment/${test_type}/${bid}/1`}
                                label="Start health assessment"
                                className="btn-start-asssessment"
                            >
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default PhlebotomyStartAssessment;