import React from "react";
import { Col, Row } from "react-bootstrap";
import { as_title, format_value, toSentenceCase } from "../../utils/utils";

/**
 * Displays a list of items based on the provided fields and data.
 * It maps through the `fields` and renders a `DisplayItem` for each field.
 * @returns {JSX.Element} A row containing `DisplayItem` components.
 */

const DisplayItems = ({ fields, data }) => (
  <Row>
    {fields.map(field => (
      <DisplayItem
        key={field.name}
        field_name={field.name}
        field={field}
        data={data}
      />
    ))}
  </Row>
);

/**
 * Renders a single display item with its value, label, and optional help text.
 * This component takes a field name and looks up the corresponding value in the data object.
 * It also displays additional details like help text if provided.
 * @returns {JSX.Element} A column containing the display item for a single field.
 */

const DisplayItem = ({ field_name, field, data }) => {
  const value = data[field_name];
  return (
    <Col md={6}>
      <div className="border-top pt-2 pb-4">
        <h4 className="mb-1">
          {toSentenceCase(field.label) || toSentenceCase(field.title) || toSentenceCase(as_title(field_name))}
        </h4>
        <h5 className="font-weight-normal">
          {format_value(value, field.fmt, field.choices)}
        </h5>
        {field.helpText && (
          <small className="text-muted">{toSentenceCase(field.helpText)}</small>
        )}
      </div>
    </Col>
  );
};

export default DisplayItems;
