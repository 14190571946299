import React, { useEffect, useState } from "react";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { setPasswordFields } from "../../stories/Forms/fields";
import { setPasswordSchema } from "../../stories/Forms/validationSchema";
import { BoxArrowInRight, XSquare } from "react-bootstrap-icons";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { setPasswordUrl } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * SetPassword Component
 *
 * This component allows users to set or reset their passwords based on a token
 * received via email. It fetches user details and validates the token before
 * allowing the user to set a new password.
 *
 * @returns {JSX.Element} The rendered SetPassword component.
 */

const SetPassword = () => {
  const { callApi, loading, serverError, resultGet } = useApis();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.pathname.split("/");

  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({ email: "" });
  const [password, setPassword] = useState("");


  // Shows toast notification on server error
  useEffect(() => {
    if (serverError) {
      auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
      navigate("/login");
    }
  }, [serverError]);

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      // Only fetch data if the user data is not already present
      if (!user) {
        const response = await callApi(
          null,
          `${setPasswordUrl}/${params[2]}/${params[3]}/`,
          "GET"
        );
        if (response) {
          setUser(response);
        }
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    if (resultGet?.data)
      setFormData({ email: resultGet?.data?.email });
  }, [resultGet])

  // Handles form submission for password reset
  const handleReset = async values => {
    const payload = { ...values };
    // Calls API to submit new password
    const response = await callApi(
      payload,
      `${setPasswordUrl}/${params[2]}/${params[3]}/`,
      "POST"
    );

    if (response) {
      navigate("/login");
      // Shows success message if reset is successful
      auth?.showToast(
        "Success",
        "Password reset successfully!",
        "../assets/mhc.png",
        <BoxArrowInRight />
      );
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }


  return (
    <div className="initial-form-wrapper">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="py-4">
            <h1>Set password</h1>
            <p className="text-muted">
              {/* Dynamically displays email if user data is fetched */}
              <p className="text-muted">
                {user ? (
                  <>
                    Enter a new password to set the password for{" "}
                    <span className="text-email">{user?.data?.email}</span>.
                  </>
                ) : (<></>)}
              </p>
            </p>
            <p className="text-muted">
              If you are having trouble logging in or setting your password, please email{" "}
              <a href="mailto:care@myhealthchecked.com">
                care@myhealthchecked.com
              </a>
              .
            </p>

            {/* DynamicForm Component with onSubmit */}
            <DynamicForm
              key={formData.email}
              fields={setPasswordFields}
              initialValues={formData}
              onSubmit={handleReset}
              validationSchema={setPasswordSchema}
              password={password}
              setPassword={setPassword}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
