import React, { useEffect, useState } from "react";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { resetPassword } from "../../stories/Forms/fields";
import { resetPassSchema } from "../../stories/Forms/validationSchema";
import { XSquare } from "react-bootstrap-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import useApis from "../../services/useAPI";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { resetPasswordUrl } from "../../utils/constants";

/**
 * ResetPassword Component
 *
 * This component provides a form for users to request a password reset.
 * It handles form submission, API integration for sending reset emails, 
 * and displays success or error messages via toast notifications.
 * 
 * @returns {JSX.Element} The rendered ResetPassword component.
 */

const ResetPassword = () => {
  const { callApi, loading, serverError, resultPost } = useApis();
  const auth = useAuth();
  const [doneEmail, setDoneEmail] = useState(null);
  const [submittedEmail, setSubmittedEmail] = useState(null);
  const [toastShown, setToastShown] = useState(false);

  // Shows toast notification on server error
  useEffect(() => {
    if (serverError && !toastShown) {
      auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
      setToastShown(true);
    }
  }, [serverError, toastShown, auth]);

  // Handles form submission for password reset
  const handleReset = async values => {
    const payload = {
      email: values.email,
      recaptcha_token: values.recaptcha_token
    };

    // Resets toast control state before making API call
    setToastShown(false);

    setSubmittedEmail(values.email); // Set the submitted email
    await callApi(payload, resetPasswordUrl, "POST");
  };

  useEffect(() => {
    if (resultPost?.data && !toastShown) {
      setDoneEmail(submittedEmail); // Use the submitted email state
      auth?.showToast(
        "Email Sent",
        `Email sent to ${submittedEmail}`, // Use the submitted email state
        "../assets/mhc.png",
        <FontAwesomeIcon icon={faEnvelope} />
      );
      setToastShown(true);
    }
  }, [resultPost, submittedEmail, toastShown, auth]);

  return (
    <div className="initial-form-wrapper">
      <div className="row justify-content-center">
        <div className="col-md-8">
            <h1 className="mb-4">Reset password</h1>
            {!doneEmail ? (
              <>
                <p>
                  If you've forgotten your password, fill in the form below.
                  We'll send you an email with a link to reset your password or
                  more information.
                </p>
                <p>
                  If you are having trouble logging in, please email{" "}
                  <a href="mailto:care@myhealthchecked.com">
                    care@myhealthchecked.com
                  </a>
                  .
                </p>
                <DynamicForm
                  fields={resetPassword}
                  onSubmit={handleReset}
                  validationSchema={resetPassSchema}
                />
                {loading && <p>Submitting...</p>}
              </>
            ) : (
              <>
                <p className="text-muted">
                  An email has been sent to{" "}
                  <span className="text-email">{doneEmail}</span> with
                  instructions on resetting your password.
                </p>
                <p className="text-center">You can safely close this window.</p>
              </>
            )}

        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
