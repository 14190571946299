import moment from "moment";
import { useEffect, useState } from "react";
import useApis from "../services/useAPI";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import Glucose from "../assets/icons/Tests/DNA_Glucose_Management.svg";
import Heart from "../assets/icons/Tests/DNA_Heart_Profile.svg";
import Intolerances from "../assets/icons/Tests/DNA_Intolerances.svg";
import Vitamins from "../assets/icons/Tests/DNA_Vitamins.svg";
import Weight from "../assets/icons/Tests/DNA_Weight_Management.svg";

// Blood test icons
import Cholesterol from "../assets/icons/Tests/Blood_Cholesterol.svg";
import Energy_Profile from "../assets/icons/Tests/Blood_Energy_Profile.svg";
import Female_Sexual_Health from "../assets/icons/Tests/Blood_Female_Sexual_Health.svg";
import General_Health_Test from "../assets/icons/Tests/Blood_General_Health_Test.svg";
import Heart_Profile from "../assets/icons/Tests/Blood_Heart_Profile.svg";
import Male_Sexual_Health from "../assets/icons/Tests/Blood_Male_Sexual_Health.svg";
import Menopause from "../assets/icons/Tests/Blood_Menopause.svg";
import PCOS from "../assets/icons/Tests/Blood_PCOS.svg";
import Thyroid from "../assets/icons/Tests/Blood_Thyroid.svg";
import Erectile_Dysfunction from "../assets/icons/Tests/Blood_Erectile.svg";
import Vitamins_and_Minerals from "../assets/icons/Tests/Blood_Vitamins_and_Minerals.svg";
import Weight_Management from "../assets/icons/Tests/Blood_Weight_Management.svg";

// Biomarkers icons
import Biomarker_Cholesterol from "../assets/icons/Biomarkers/Cholesterol.svg";
import Biomarker_Full_Blood_Count from "../assets/icons/Biomarkers/Full_Blood_Count.svg";
import Biomarker_Hormones from "../assets/icons/Biomarkers/Hormones.svg";
import Biomarker_Kidney from "../assets/icons/Biomarkers/Kidney.svg";
import Biomarker_Liver from "../assets/icons/Biomarkers/Liver.svg";
import Biomarker_Sexual_Health from "../assets/icons/Biomarkers/Sexual_Health.svg";
import Biomarker_Thyroid from "../assets/icons/Biomarkers/Thyroid.svg";
import Biomarker_Vitamins_and_Minerals from "../assets/icons/Biomarkers/Vitamins_and_Minerals.svg";

export const formatReportedDate = dateString => {
  if (dateString !== " ") {
    return new Date(dateString).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
  } else {
    return " ";
  }
};

export const on_mobile = () => {
  return /mobile|ip(hone|od|ad)|android|blackberry|opera mini/i.test(
    navigator.userAgent
  );
};

export const format_date = ts => moment(ts).format("Do MMM YYYY");

const DTF = "Do /MMM /YYYY"; // Define the date format string

export const format_dt_ago = ts => {
  const m = moment(ts);
  return `${m.format(DTF)} (${m.fromNow()})`;
};

export const format_dt_age = dob => {
  const dobMoment = moment(dob); // Use moment to parse the date of birth
  const currentMoment = moment(); // Get the current date using moment

  // Calculate the age
  const age = currentMoment.diff(dobMoment, "years");

  // Format the date of birth and return the result
  return `${dobMoment.format("DD/MM/YYYY")} (${age} years old)`;
};

export const formatDob = dateString => {
  const [month, day, year] = dateString.split("/");

  // Check if the input date is valid
  if (!month || !day || !year) {
    throw new Error("Invalid date format. Must be in MM/DD/YYYY format.");
  }

  // Convert to YYYY-MM-DD format
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

export const getMinDate = () => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 18); // Subtract 18 years
  return currentDate.toISOString().split('T')[0]; // Return as YYYY-MM-DD format
};

export const formattedPhoneNumber = (phoneNumber) => {
  // Ensure phoneNumber is a string before calling replace
  return phoneNumber ? phoneNumber.replace(/\s+/g, '') : '';
};


/**
/**
 * Format data by trimming strings and converting dob to yyyy-dd-mm format.
 * @param {Object} data - The data object to format.
 * @returns {Object} - The formatted data object.
 */
const formatData = data => {
  // Create a new object with trimmed values
  const trimmedData = Object.keys(data).reduce((acc, key) => {
    acc[key] = typeof data[key] === "string" ? data[key].trim() : data[key];
    return acc;
  }, {});

  // Return the trimmed data object without modifying dob
  return trimmedData;
};

export default formatData;

export const formatBarcode = barcode => {
  let cleanedBarcode = barcode.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters

  let firstSegment = cleanedBarcode
    .slice(0, 3)
    .toUpperCase()
    .replace(/[^A-Z]/g, ""); // Capitalize and remove non-alphabets
  let secondSegment = cleanedBarcode
    .slice(3, 6)
    .replace(/[^a-zA-Z]/g, "")
    .toLowerCase(); // Remove non-alphabets
  let remainingSegment = cleanedBarcode.slice(6, 10);

  if (remainingSegment) {
    return `${firstSegment}-${secondSegment}-${remainingSegment}`;
  } else if (secondSegment) {
    return `${firstSegment}-${secondSegment}`;
  } else {
    return firstSegment;
  }
};

export const statusMapping = {
  registered: { variant: "primary", label: "Registered" },
  created: { variant: "primary", label: "Registered" },
  processing: { variant: "yellow", label: "Processing" },
  in_transit: { variant: "powder-pink", label: "In Transit" },
  processing_consultation: { variant: "mint", label: "Processing" },
  processing_mint: { variant: "mint", label: "Processing" },
  processing_raspberry: { variant: "raspberry", label: "Processing" },
  results_ready: { variant: "dark-green", label: "Results Ready" },
  activating: { variant: "grey-lilac", label: "Activating" },
  rejected: { variant: "raspberry", label: "Rejected" }
};

// Define a function to handle the asynchronous update of meet_details
export const TimeFormatter = (date, time) => {
  return new Promise(resolve => {
    if (!date || !time) {
      resolve("");
      return;
    }

    // Combine date and time into a single string
    const combinedDateTimeStr = `${date}T${time}`;

    // Parse the combined string into a Date object
    const dateObj = new Date(combinedDateTimeStr);

    // Check if the dateObj is valid
    if (isNaN(dateObj.getTime())) {
      resolve("");
      return;
    }

    // Extract components
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    const hours = String(dateObj.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(dateObj.getUTCMilliseconds()).padStart(3, "0");

    // Get timezone offset
    const offset = -dateObj.getTimezoneOffset();
    const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");
    const sign = offset >= 0 ? "+" : "-";

    // Construct the formatted datetime string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${sign}${offsetHours}:${offsetMinutes}`;

    //resolve the promise
    resolve(formattedDateTime);
  });
};

// Get icon by test name
export const iconHelper = name => {
  let icon = null;
  const iconList = [
    // DNA
    Glucose,
    Heart,
    Intolerances,
    Vitamins,
    Weight,

    // Blood
    Cholesterol,
    Energy_Profile,
    Male_Sexual_Health,
    General_Health_Test,
    Heart_Profile,
    Female_Sexual_Health,
    Menopause,
    PCOS,
    Thyroid,
    Erectile_Dysfunction,
    Vitamins_and_Minerals,
    Weight_Management,

    // Biomarkers
    Biomarker_Cholesterol,
    Biomarker_Full_Blood_Count,
    Biomarker_Hormones,
    Biomarker_Kidney,
    Biomarker_Liver,
    Biomarker_Sexual_Health,
    Biomarker_Thyroid,
    Biomarker_Vitamins_and_Minerals
  ];

  const testNames = [
    // DNA
    "Glucose",
    "Heart",
    "Intolerances",
    "Vitamins",
    "Weight",

    // Blood
    "Cholesterol Profile Test",
    "Energy Profile",
    "Male Sexual Health",
    "General Health",
    "Heart Profile",
    "Female Sexual Health",
    "Menopause",
    "PCOS",
    "Thyroid",
    "Erectile Dysfunction",
    "Vitamins & Minerals Profile Test",
    "Weight Management",

    // Biomarkers
    "Biomarker Cholesterol",
    "Biomarker Full Blood Count",
    "Biomarker Hormones",
    "Biomarker Kidney",
    "Biomarker Liver",
    "Biomarker Sexual Health",
    "Biomarker Thyroid",
    "Biomarker Vitamins and Minerals"
  ];

  for (let i = 0; i < iconList.length; i++) {
    if (
      String(name)
        .toLowerCase()
        .includes(testNames[i].toLowerCase())
    ) {
      // eslint-disable-next-line no-unused-vars
      return icon = iconList[i];
    }
    else if (name === 'Male Sexual Health Test') {
      return icon = Male_Sexual_Health
    }
    else if (name === 'Female Sexual Health Test') {
      return icon = Female_Sexual_Health
    }
  }
};

// Function to convert a string with underscores or dashes to title case
export const as_title = s => {
  return (s || "")
    .replace(/[ _-]/g, " ")
    .replace(/([ _-]|\b)\w/g, l => l.toUpperCase())
    .replace(/\bdna\b/gi, "DNA");
};

export function format_value(value, fmt, choices) {
  // Handles boolean values without fmt
  if (typeof value === "boolean" && !fmt) {
    const icon = value ? faCheck : faTimes;
    return <FontAwesomeIcon icon={icon} />;
  }

  // Returns a muted dash for falsy but defined values
  if (!value) {
    return <span className="text-muted">&mdash;</span>;
  }

  // Handles choices
  if (choices) {
    const selected = choices.find(c => c.value === value);
    return selected ? selected.label : value;
  }

  // Checks for fmt being defined
  if (!fmt) {
    return toSentenceCase(value);
  }

  // Formats based on fmt
  switch (fmt) {
    case "datetime":
      return format_dt_ago(value);
    case "date":
      return format_date(value);
    case "inline-code":
      return <code className="inline-code">{value}</code>;
    case "as-title":
      return toSentenceCase(as_title(value));
    case "as-country":
      if (window.country_lookup) {
        return toSentenceCase(window.country_lookup[value]) || toSentenceCase(value);
      }
      return toSentenceCase(value);
    default:
      return toSentenceCase(value);
  }
}

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

// Function to convert string into a URL-friendly format (slug)
const slugify = s => s?.toLowerCase().replace(/\s/, '-') || '';

// Function to track events with associated data
export function track(event, label) {
  const data = {
    event: slugify(event),
    label: slugify(label),
    page_width: window.innerWidth,
    page_height: window.innerHeight,
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('tracking', data)
  } else {
    fetch('/track/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  }
}

// Defines colors and labels for different effects
export const effect_data = {
  risk: {
    color: "nell-purple-2",
    leftLabel: "Lower Risk",
    rightLabel: "Higher Risk",
    centreLabel: "Medium Risk"
  },
  advantage: {
    color: "nell-blue",
    leftLabel: "Lower Advantage",
    rightLabel: "Higher Advantage",
    centreLabel: "Medium Advantage"
  },
  interest: {
    color: "nell-orange",
    leftLabel: "Lower Interest",
    rightLabel: "Higher Interest",
    centreLabel: "Medium Interest"
  }
};

// Function to determine intensity level
export const getIntensity = (intensity, effect) => {
  if (effect === "risk") {
    if (intensity === 0.0) {
      return "low-intensity";
    } else if (intensity === 1.0) {
      return "high-intensity";
    }

    return "medium-intensity";
  }

  if (intensity === 1.0) {
    return "low-intensity";
  } else if (intensity === 0.0) {
    return "high-intensity";
  }

  return "medium-intensity";
};

export const BankHolidayHelper = () => {
  const { callApi, resultGet } = useApis();
  const [isBankHoliday, setIsBankHoliday] = useState(null)

  useEffect(() => {
    const response = callApi(null, '/api/dashboard/bank-holiday', "GET")
  }, [])

  useEffect(() => {

    if (resultGet) {
      setIsBankHoliday(resultGet?.data?.is_bank_holiday)
    }

  }, [resultGet])

  return isBankHoliday
}

// Helper function to clear seconds and milliseconds
export const clearSecondsAndMilliseconds = (date) => {
  return new Date(date.setSeconds(0, 0));
};

// Converts string to sentence case
export function toSentenceCase(str) {
  if (!str) return "";

  // Helper function to check if a word is an abbreviation
  const isAbbreviation = (word) => /^[A-Z]+$/.test(word);

  // Split the string into an array of words
  const words = str.split(" ");

  const transformedWords = words.map((word, index) => {
    // If the word is inside parentheses, leave it as-is
    if (word.startsWith("(") && word.endsWith(")")) {
      return word; // Leave words like "(PCOS)" intact
    }

    // If the word is an abbreviation, leave it as-is
    if (isAbbreviation(word.replace(/[()]/g, ""))) {
      return word; // Handle abbreviations like "PCOS"
    }

    // Capitalize the first word
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    // For other words, lowercase them
    return word.toLowerCase();
  });

  // Join the words back into a sentence
  return transformedWords.join(" ");
}

