import React, { useState, useEffect } from "react";
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Form
} from "react-bootstrap";
import PropTypes from "prop-types";
import { useAuth } from "../../components/AuthContext/AuthContext";

const ToggleButtons = ({
  field,
  toggleValueProp,
  genderIdentityProp,
  setFieldValue,
  noLabel,
  errors,
  touched,
  type
}) => {
  const [toggleValue, setToggleValue] = useState("");
  const [genderIdentity, setGenderIdentity] = useState("");
  const auth = useAuth();
  // Sync toggleValue with toggleValueProp
  useEffect(() => {
    setToggleValue(toggleValueProp || "");
    if (field.name === "units") {
      let value = toggleValueProp ? toggleValueProp : "Metric"
      setToggleValue(value);
      setFieldValue(value)
    }
  }, [toggleValueProp]);

  // Sync genderIdentity with genderIdentityProp
  useEffect(() => {
    setGenderIdentity(genderIdentityProp || "");
  }, [genderIdentityProp]);

  if (!field || !Array.isArray(field.options)) {
    // Optionally render a fallback UI or return null
    return <div>No options available</div>; // You can customize this
  }

  if (!field || !Array.isArray(field.options)) {
    // Optionally render a fallback UI or return null
    return <div>No options available</div>; // You can customize this
  }

  return (
    <Form.Group>
      {!noLabel && (
        <Form.Label className="labels">
          {field.required ? (
            <>
              {field.label}
              <span className="required"></span>
            </>
          ) : (
            field.label
          )}
        </Form.Label>
      )}
      <ButtonToolbar>
        <ToggleButtonGroup type="radio" name={field.name}>
          {field.options.map((option, subIndex) => {
            const isSelected =
              field.name === "sex" || field.name === "units"
                ? toggleValue === option.value
                : genderIdentity === option.value;

            const variant = isSelected ? "primary" : "secondary";

            return (
              <ToggleButton
                key={subIndex}
                type={type}
                value={option.value}
                onClick={() => {
                  if (field.name === "units") {
                    if (toggleValueProp === option.value) {
                      auth.setToggled(false);
                    } else {
                      auth.setToggled(true);
                    }
                    auth.setUnitType({
                      prevUnit: auth.unitType?.currentUnit || toggleValue ? toggleValue : 'Metric', // Use the previous currentUnit or an empty string if not set
                      currentUnit: option.value
                    });

                    setToggleValue(option.value);
                    setFieldValue(field.name, option.value);
                  }
                  if (field.name === "sex") {
                    setToggleValue(option.value);
                    setFieldValue(field.name, option.value);
                  } else {
                    setGenderIdentity(option.value);
                    setFieldValue(field.name, option.value);
                  }
                }}
                variant={variant}
              >
                {option.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </ButtonToolbar>
      {errors[field.name] && touched[field.name] && (
        <div className="invalid-feedback d-block">{errors[field.name]}</div>
      )}
    </Form.Group>
  );
};

ToggleButtons.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool, // Added required prop type
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  toggleValueProp: PropTypes.string,
  genderIdentityProp: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object
};

ToggleButtons.defaultProps = {
  toggleValueProp: "",
  genderIdentityProp: "",
  errors: {},
  touched: {}
};

ToggleButtons.defaultProps = {
  toggleValueProp: "",
  genderIdentityProp: "",
  errors: {},
  touched: {}
};

export default ToggleButtons;
