import React, { useState, useEffect } from 'react';
import { Button } from '../../stories/Buttons/Button';
import { Alert, Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import useApis from '../../services/useAPI';
import { useAuth } from '../../components/AuthContext/AuthContext';

let resume_link

/**
 * Component that handles the continuation of a user's registration journey.
 * Displays modal for rejecting a booking and provides links for resuming or canceling registration.
 * 
 */

const ContinueJourney = props => {
    const [modalOpen, setModalOpen] = useState(false);
    const { callApi, resultPost } = useApis();

    const auth = useAuth();
    const navigate = useNavigate();

    // Set the correct resume link based on the current registration stage
    switch (props.resume?.registration_stage) {
        case 'type-confirm':
            resume_link = `/bloods/boots/${props.test_type}/booking/consent/${props.resume?.id}/`
            break
        case 'consent':
            resume_link = `/bloods/boots/${props.test_type}/confirm-details/${props.resume?.id}/`
            break
        case 'personal-details':
            resume_link = `/bloods/boots/assessment/${props.test_type}/${props.resume?.id}/1`
            // case 'assessment':
            //     resume_link = `/bloods/boots/assessment-form/${props.test_type}/${props.resume.id}/`
            break
        default:
            break
    }

    // API call to reject the booking
    const rejectBooking = async () => {
        try {
            // Await the response from the API
            await callApi(null, `/api/dashboard/reject-booking/${props.resume?.id}/`, "POST");
        } catch (error) {
            console.error("Error rejecting booking", error);
            auth?.showToast(
                "Reject Booking",
                "An error occurred while rejecting the booking.",
                "../assets/mhc.png",
                <faX />
            );
        }
    }

    // useEffect to show toast after API call response
    useEffect(() => {
        if (resultPost) {
            if (resultPost.status === "rejected") {
                props.isBookingRejected()
                auth?.showToast(
                    "Reject Booking",
                    "Your appointment has been rejected successfully. You can proceed to book a new one now.",
                    "../assets/mhc.png",
                    <faX />
                );
                navigate(`/bloods/boots/${props.resume?.test_type || sessionStorage.getItem('test_type')}`);
            } else {
                auth?.showToast(
                    "Reject Booking",
                    "Rejected failed",
                    "../assets/mhc.png",
                    <faX />
                );
            }
        }
    }, [resultPost]);


    // Toggle modal visibility
    const toggleModal = () => setModalOpen(!modalOpen)

    // Handle modal confirmation to reject the booking
    const handleRejectBooking = () => {
        toggleModal()
    }

    // Reject an appointment
    const handleConfirmReject = () => {
        rejectBooking()
        toggleModal()
    }
    return (
        <>
            <div className="row justify-content-center dashboard-page">
                <div className="col-md-12">
                    <div className="dashboard">
                        <h1 className="mb-4">ACTION REQUIRED</h1>
                        <hr />
                        {props.untestable && (
                            <div className="mt-4">
                                <div>
                                    <p>
                                        We regret to inform you that our partner lab was unable to successfully analyze your sample(s). This may
                                        happen due to several reasons such as leakage during transit, clotted samples, or heamolysis.
                                    </p>
                                    <p>To book a new appointment, please click on the button below.</p>
                                </div>
                                <Button color="primary" href="https://www.boots.com/wellnesstestrebook" target="_blank" label="Rebook Appointment" />
                            </div>
                        )}
                        {props.resume && (
                            <div className="mt-4">
                                <h4>
                                    Your registration with Boots In-store Wellness Testing Service powered by MyHealthChecked is not yet
                                    complete.
                                </h4>
                                <p>Please click the button below to continue your registration.</p>
                                <Button color="primary" href={resume_link} label="Continue registration" />
                                <Alert className="mt-4 text-justify" variant="warning">
                                    <strong class="alert-heading">Important Reminder: </strong>
                                    <p>
                                        You can only register for one test at a time. Once you’ve received your test results, you’ll be able to
                                        register for another.
                                    </p>
                                    <p>
                                        <strong>Please note:</strong> It's essential to complete your registration before arriving at Boots for
                                        your in-store appointment. If your registration is incomplete, you may be unable to proceed with your
                                        test.
                                    </p>
                                    <p>If you'd like to cancel your current registration, please select "Cancel" below.</p>
                                    <span>
                                        <Button className="btn-cancel" label="Cancel registration" onClick={handleRejectBooking} />
                                    </span>
                                </Alert>
                                <Modal show={modalOpen} onHide={toggleModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirm Rejection</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure you want to reject this appointment? This action cannot be undone.</Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn-grey" onClick={toggleModal} label="Cancel" />
                                        <Button className="btn-reject" onClick={handleConfirmReject} label="Confirm" />
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </>
    );
};

export default ContinueJourney;