import { Alert } from "react-bootstrap";
import { Button } from "../../stories/Buttons/Button";
import { Link } from "react-router-dom";

/**
 * AssessmentMessage Component
 * Displays an alert with actionable messages based on assessment status.
 * @returns {JSX.Element | null} - Rendered component or null
 */

export function AssessmentMessage({ assessment, test_id, bId }) {
  // Show message only when assessment can be edited
  if (!assessment.canEditAssessment) return null;

  // If filled assessment, show edit message
  if (assessment.hasFilledAssessment) {
    return (
      <>
        <Alert className="mt-3 rounded" variant="info"  fade={false}>
          Feel free to edit your Health Assessment to provide the most accurate
          details for our doctor.
          <br />
          <Button
            href={`/assessment-details/${test_id}`}
            color="info"
            className="mt-2 btn-info"
            size="sm"
            label="Edit assessment"
          />

        </Alert>
      </>
    );
  }

  // Otherwise - there are no assessment yet
  return (
    <>
      <Alert className="mt-3 rounded" variant="warning" fade={false}>
        Please{" "}
        <strong>
          <Link to={`/assessment/${test_id}/1`} className="alert-link">
            complete your Health Assessment
          </Link>
        </strong>
        . Our doctor cannot review your results effectively without this
        information.
        <br />
        <Button
          href={`/assessment/${test_id}/1`}
          variant="warning"
          className="mt-2 btn-warning"
          size="sm"
          label='Fill assessment'
        />
      </Alert>
    </>
  );
}
