import React, { useEffect, useState } from "react";
import { Spinner, Button } from "react-bootstrap";
import HealthAreaSection, { getStatusSexualHealth } from "./HealthAreaSection";
import { MobileHealthArea } from "./MobileHealthArea";
import "../../../assets/scss/_blood-report-page.scss";
import drDaveNichols from "../../../assets/images/doctors/Dave_Nichols.jpg";
import drGavinRalston from "../../../assets/images/doctors/Gavin_Ralston.jpg";
import drIcon from "../../../assets/icons/Doctor.svg";
import { DoctorLetterModal } from "./DoctorLetterModal";
import { format_date_reports } from "../../../utils";
import { iconHelper } from "../../../utils/utils";
import DoctorLetter from "./DoctorLetter";
import HorizontalSection from "./HorizontalSection";
import useApis from "../../../services/useAPI";
import { useParams } from "react-router-dom";

const doctorPictures = {
  "Dave Nichols": drDaveNichols,
  "Gavin Ralston": drGavinRalston
};

/**
 * Retrieves the doctor's image based on their name.
 */

export function getDoctorImg(name) {
  return doctorPictures[name];
}

/**
 * Gets the status of the health area.
 */
function getAreaStatus(health_area) {
  // If the area is not "sexual health"
  if (String(health_area?.area)?.toLowerCase() !== "sexual health") {
    const areaStatuses = health_area.health_areas.map((area) => area.area_status);
    return areaStatuses; 
  }

  // For "sexual health," process markers
  return getStatusSexualHealth(health_area.markers);
}


const DoctorNotesSection = "doctor_notes";

/**
 * BloodReportPage component
 * This component displays a detailed blood report, including sections for
 * health areas and doctor's notes. It manages loading states and handles
 * the display of the doctor's letter in a modal.
 */
function BloodReportPage() {
  const params = useParams()
  const { test_kit_id } = params
  const [report, setReport] = useState(null);
  const [currentSection, setCurrentSection] = useState(DoctorNotesSection);
  const [letterModalVisible, setLetterModalVisibility] = useState(false);
  const getApiUrl = `/api/dashboard/report/${test_kit_id}`; //3 for cholestrol and 19 for SH
  const { callApi, resultGet } = useApis();

  /**
   * Loads the blood report data from the server.
   */

  useEffect(() => {
    callApi({}, getApiUrl, "GET");
  }, []);

  useEffect(() => {
    setReport(resultGet?.data);
  }, [resultGet]);

  if (!report) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );
  }

  /**
   * Toggles the visibility of the doctor's letter modal.
   */
  const showDrLetter = () => {
    setCurrentSection(DoctorNotesSection);
    const isSmallScreen = window.innerWidth < 700;

    if (isSmallScreen) {
      setLetterModalVisibility(!letterModalVisible);
    }
  };

  /**
   * Renders the doctor's letter content.
   *
   * @returns {JSX.Element} The rendered doctor's letter component.
   */

  const renderDoctorLetter = () => (
    <>
      <DoctorLetter
        doctor={report?.assigned_doctor}
        patient={report?.customer}
        overallStatus={report?.overall_status}
        testName={report?.title}
        content={report?.doctors_letter || ""}
        missingAssessment={!report?.assessment_id}
      />
    </>
  );

  let navItems = [];

  navItems.push({
    name: DoctorNotesSection,
    label: `Doctor's letter`,
    icon: drIcon,
    background: "#F9EFE2",
    content: renderDoctorLetter
  });

  // Single health area report
  if (report?.health_areas?.length === 1) {
    navItems.push({
      area: { ...report.health_areas[0], area: "Your results" },
      name: `${report.report_type}`,
      label: "Your results",
      status: getAreaStatus(report),
      icon: iconHelper(`Biomarker ${report.title}`),
      content: () => (
        <HealthAreaSection
          area_status={report?.area_status}
          {...report.health_areas[0]}
          area={report.title}
          type={report.report_type}
        />
      )
    });
  } else {
    // Multiple health areas
    report?.health_areas?.forEach((area, index) => {
      const processedAreaStatus = report?.health_areas[index]?.area_status; // Access area_status using index
      navItems.push({
        area: area,
        area_status: processedAreaStatus,
        name: area.area,
        label: area.area,
        status: getAreaStatus(report),
        icon: iconHelper(`Biomarker ${area.area}`),
        content: () => <HealthAreaSection area_status={processedAreaStatus} {...area} type={report.report_type} />
      });
    });
    


    // Sort navItems alphabetically
    let nonDoc = navItems.splice(1);
    nonDoc.sort((a, b) => a.name.localeCompare(b.name));
    navItems = navItems.concat(nonDoc);
  }

  const mobileItems = navItems.filter(item => item.name !== DoctorNotesSection);

  const testIcon = iconHelper(report.title);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="mb-4">
            <section className="blood-report-container">
              {/* Header */}
              <header className="blood-report-header">
                {testIcon && (
                  <div className="blood-report-header-icon">
                    <img src={testIcon} alt={report?.title} />
                  </div>
                )}
                <div className="blood-report-header-main">
                  <h1>{report?.title}</h1>
                  <p className="blood-report-header-summary">
                    Welcome {report?.customer?.split(" ")[0]}!
                  </p>
                  {report?.activated_at && (
                    <p className="blood-report-header-details">
                      Date Test Taken:{" "}
                      <strong>
                        {format_date_reports(report?.activated_at)}
                      </strong>
                    </p>
                  )}
                </div>

                <aside className="blood-report-header-sidebar">
                  {getDoctorImg(report?.assigned_doctor) && (
                    <img
                      src={getDoctorImg(report?.assigned_doctor)}
                      alt={report?.assigned_doctor}
                    />
                  )}
                  <div className="blood-report-sidebar-button-main ">
                    <p>Analysed by</p>
                    <h2>Dr {report?.assigned_doctor}</h2>

                    <Button onClick={showDrLetter} variant="primary">
                      read{" "}
                      <span className="blood-report-sidebar-button-extra-copy">
                        doctor's{" "}
                      </span>
                      letter
                    </Button>
                  </div>
                </aside>
              </header>

              {/* Desktop side nav */}
              <div className="blood-report-main--desktop">
                <HorizontalSection
                  items={navItems}
                  setCurrentItem={setCurrentSection}
                  current={currentSection}
                />
              </div>

              {/* Mobile carousel */}
              <div className="blood-report-main--mobile">
                {mobileItems.map((item, key) => (
                  <MobileHealthArea
                    key={key}
                    {...item.area}
                    type={report?.report_type}
                    expanded={mobileItems.length === 1}
                    collapsible={mobileItems.length !== 1}
                  />
                ))}
              </div>
              {/* Doctor letter mobile modal */}
              <DoctorLetterModal
                title={`Letter from doctor`}
                isOpen={letterModalVisible}
                toggle={showDrLetter}
              >
                {renderDoctorLetter()}
              </DoctorLetterModal>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default BloodReportPage;
