import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from "react";
import Toaster from "../../stories/Toasts/Toaster";
import { useLocation, useNavigate } from "react-router-dom";

// Creates AuthContext for managing authentication and authorization
const AuthContext = createContext();

// Provider component to manage authentication and authorization state
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token") || ""
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refresh_token") || ""
  );
  const [user, setUser] = useState(localStorage.getItem("user_id"));
  const [showWarning, setShowWarning] = useState(false);
  const [userInitials, setUserInitials] = useState("guest");
  const [toast, setToast] = useState(null);
  const [completedSteps, setCompletedSteps] = useState(1);
  const [convertValues, setConvertValues] = useState(false);
  const [unitType, setUnitType] = useState("Metric");
  const [toggled, setToggled] = useState(false);
  const [nextURl, setNextUrl] = useState();
  const [editReload, setEditReload] = useState(false);
  const [step, setStep] = useState(completedSteps);
  const [isCompleted, setIsCompleted] = useState(0);
  const [allStepData, setAllStepData] = useState({});
  const [kitId, setKitId] = useState();
  const [stepId, setstepId] = useState();
  const [totalSteps, setTotalSteps] = useState(6);

  const location = useLocation();
  const isPhlebotomyTest = location.pathname.includes('boots') || JSON.parse(localStorage.getItem('is_phlebotomist'))

  const showToast = (header, body, image, icon) => {
    const Icon = icon;
    setToast({
      header: header,
      body: body,
      imgSrc: image,
      icon: Icon
    });
  };
  const navigate = useNavigate();
  useEffect(() => {
    setstepId(location.pathname.split('/').at(-1))
    setKitId(location.pathname.split('/').at(-2))
  }, [location?.pathname])
  const handleNextStep = useCallback(() => {
    setIsCompleted(1); // Increment the step based on the latest value
    setCompletedSteps(prevSteps => prevSteps + 1); // Use functional update to access the latest value
    setStep(prevStep => prevStep + 1);
  }, []);

  const handlePreviousStep = useCallback((test_kit_id, type) => {
    setCompletedSteps(prevSteps => prevSteps - 1); // Use functional update to access the latest value
    setStep(prevStep => prevStep - 1);
    const pathSegments = location.pathname.split('/').filter(Boolean); // Filter out empty segments

    if (isPhlebotomyTest) {
      // Ensure the required path segments are available
      const stepNumber = parseInt(pathSegments.at(-1)) || 1; // Default to 1 if undefined
      const bid = pathSegments.at(-2) || ''; // Provide a fallback value
      const test_type = pathSegments.at(-3) || ''; // Provide a fallback value
      navigate(`/bloods/boots/assessment/${test_type}/${bid}/${stepNumber - 1}`);
    } else {
      navigate(`/assessment/${kitId || test_kit_id}/${parseInt(stepId) - 1}`)

    }
  }, [kitId, stepId]);

  // Function to set JWT tokens
  const setTokens = (tokens, email, role) => {
    if (tokens) {
      localStorage.setItem("access_token", tokens.access_token);
      localStorage.setItem("refresh_token", tokens.refresh_token);
      localStorage.setItem("email", email);
      localStorage.setItem("role", role);
      setAccessToken(tokens.access_token);
      setRefreshToken(tokens.refresh_token);
      setEmail(email);
      setRole(role);
    }
  };
  // Inactivity and warning time constants
  const INACTIVITY_TIME = 2 * 60 * 60 * 1000;
  const WARNING_TIME = 5 * 60 * 1000;
  let warningTimeout;
  let logoutTimeout;

  // Reset inactivity timers
  const resetTimers = useCallback(() => {
    clearTimeout(warningTimeout);
    clearTimeout(logoutTimeout);

    if (isAuthenticated) {
      warningTimeout = setTimeout(() => {
        setShowWarning(true);
      }, INACTIVITY_TIME);

      logoutTimeout = setTimeout(() => {
        setShowWarning(false);
        logout();
      }, INACTIVITY_TIME + WARNING_TIME);
    }
  }, [isAuthenticated]);

  // Handle user activity to reset timers
  const handleUserActivity = useCallback(() => {
    resetTimers();
    setShowWarning(false);
  }, [resetTimers]);

  // Effect to add/remove event listeners for user activity
  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    if (isAuthenticated) {
      resetTimers();
    }

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, [isAuthenticated, handleUserActivity, resetTimers]);

  // For dynamic rendering of User initials in profile icon
  useEffect(() => {
    setUserInitials(localStorage.getItem("username"));
  }, [isAuthenticated]);

  // Login function to set authentication state
  const login = data => {
    !data.mfa_enabled && localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("email", data.email);
    localStorage.setItem("username", data.fullname);
    localStorage.setItem("mfa_enabled", data.mfa_enabled || false);
    data.id && localStorage.setItem("user_id", data.id);
    setRole(data.role);
    setAccessToken(
      data.access_token
        ? data.access_token
        : localStorage.getItem("access_token")
    );
    setRefreshToken(
      data.refresh_token
        ? data.refresh_token
        : localStorage.getItem("refresh_token")
    );
    data.id && setUser(data.id);
    setTimeout(() => {
      setIsAuthenticated(localStorage.getItem("isLoggedIn") === "true");
      resetTimers();
    }, 500);
  };

  // Logout function to clear authentication state
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.clear();
    clearTimeout(warningTimeout);
    clearTimeout(logoutTimeout);
  };

  // Effect to handle changes in local storage
  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(localStorage.getItem("isLoggedIn") === "true");
      setAccessToken(localStorage.getItem("access_token") || "");
      setRefreshToken(localStorage.getItem("refresh_token") || "");
      setUser(localStorage.getItem("user_id"));
      setRole(localStorage.getItem("role"));
      setEmail(localStorage.getItem("email"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);


  const contextValue = useMemo(() => ({
    isAuthenticated,
    setIsAuthenticated,
    showToast,
    toast,
    setToast,
    login,
    logout,
    role,
    setTokens,
    user,
    updateState: setUser,
    setTitle,
    title,
    email,
    setEmail,
    accessToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
    totalSteps,
    setTotalSteps,
    completedSteps,
    setCompletedSteps,
    showWarning,
    userInitials,
    convertValues,
    setConvertValues,
    unitType,
    setUnitType,
    toggled,
    setToggled,
    nextURl,
    setNextUrl,
    editReload,
    setEditReload,
    step,
    setStep,
    isCompleted,
    setIsCompleted,
    handleNextStep,
    handlePreviousStep,
    allStepData,
    setAllStepData
  }), [
    isAuthenticated,
    showToast,
    toast,
    role,
    user,
    title,
    email,
    totalSteps,
    setTotalSteps,
    accessToken,
    refreshToken,
    completedSteps,
    showWarning,
    userInitials,
    convertValues,
    unitType,
    toggled,
    nextURl,
    editReload,
    step,
    setStep,
    handleNextStep,
    isCompleted,
    setIsCompleted,
    handlePreviousStep,
    allStepData,
    setAllStepData
  ]);


  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      {toast && (
        <Toaster
          header={toast.header}
          body={toast.body}
          imgSrc={toast.imgSrc}
          icon={toast.icon}
          autoHideDuration={3000}
          onClose={() => setToast(null)}
          newToast={toast}
        />
      )}
    </AuthContext.Provider>
  );
};

// Hook to use AuthContext
export const useAuth = () => useContext(AuthContext);
