import React, { useEffect } from "react";
import { Line } from "rc-progress";
import check from "../../assets/icons/Icon.svg";
import { useAuth } from "../../components/AuthContext/AuthContext";

const StepProgressBar = ({
  steps,
  currentStep,
  isCompleted,
  completedSteps,
  is_assessment_completed
}) => {
  const auth = useAuth();
  const progressPercentage = (currentStep / steps) * 100;

  useEffect(() => {
    if (is_assessment_completed) {
      auth.setIsCompleted(true);
    }
  }, [is_assessment_completed, isCompleted])

  return (
    <>
      {is_assessment_completed ? (
        // When assessment is completed
        <div className="progress-bar-container">
          <Line
            percent={100}
            strokeWidth="5"
            trailWidth="5"
            strokeColor="#49A49D"
            trailColor="#C6C6C6"
          />
          <div className="steps">
            {Array.from({ length: steps }).map((_, index) => (
              <div
                key={index}
                className={`step ${index === currentStep - 1 ||
                  (index >= completedSteps &&
                    index >= currentStep && index >= isCompleted)
                  ? "current-step"
                  : index < currentStep && index < isCompleted && "completed"
                  }`}
              >
                <a
                  className={`step ${index === currentStep - 1 ||
                    (index >= completedSteps &&
                      index >= currentStep && index >= isCompleted)
                    ? "current-step"
                    : index < currentStep && index < isCompleted && "completed"
                    }`}
                >
                  <img src={check} className="completed-icon" />

                </a>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // When assessment is not completed
        <div className="progress-bar-container">
          <Line
            percent={progressPercentage}
            strokeWidth="5"
            trailWidth="5"
            strokeColor="#49A49D"
            trailColor="#C6C6C6"
          />
          <div className="steps">
            {Array.from({ length: steps }).map((_, index) => (
              <div
                key={index}
                className={`step ${index === currentStep - 1 ||
                  (index >= completedSteps &&
                    index >= currentStep && index >= isCompleted)
                  ? "current-step"
                  : index < currentStep && index < isCompleted && "completed"
                  }`}
              >
                <a
                  className={`step ${index === currentStep - 1 ||
                    (index >= completedSteps &&
                      index >= currentStep && index >= isCompleted)
                    ? "current-step"
                    : index < currentStep && index < isCompleted && "completed"
                    }`}
                >
                  {index < currentStep && index < isCompleted ? (
                    <img src={check} className="completed-icon" />
                  ) : (
                    index + 1
                  )}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </>

  );
};

export default StepProgressBar;
