import React, { useEffect, useState } from "react";
import { LinkCard } from "../stories/cards/LinkCard";
import Buy from "../assets/icons/Platform/Buy_tests.svg";
import PCR from "../assets/icons/Platform/PCR_tests.svg";
import {
  as_title,
  format_date,
  iconHelper,
  toSentenceCase
} from "../utils/utils";
import { myResultUrl } from "../utils/constants";
import useApis from "../services/useAPI";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getTestNameFromType } from "../utils";

/**
 * TestList Component
 * Fetches and displays the user's tests and their results.
 */

const TestList = () => {
  const { id } = useParams();
  const [testData, setTestData] = useState([]);
  // const {allTestData, setAllTestData} = useState([])
  const { callApi, isLoading, serverError, resultGet } = useApis();

  useEffect(() => {
    const fetchTestData = async () => {
      try {
        await callApi(null, myResultUrl, "GET");
      } catch (error) {
        console.error("API error:", error);
      }
    };
    fetchTestData();
  }, [id]);

  useEffect(() => {
    if (resultGet?.success) {

      if (resultGet?.phlebotomist_bookings?.data) {
        setTestData([
          ...resultGet?.test_kits?.data,
          ...resultGet?.phlebotomist_bookings?.data
        ]);
      } else {
        setTestData(resultGet?.test_kits?.data)
      }
    }

  }, [resultGet]);

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );
  }

  // Error state
  if (serverError) {
    return <p className="text-muted">{serverError}</p>;
  }

  return (
    <div className="activate-box-wrapper">
      <div className="row">
        <div className="col-md-12">
          <h1 className="text-left mb-4">My results</h1>

          {testData?.length === 0 && !isLoading ? (
            <div>
              <p className="text-muted">You don't have any tests yet.</p>
              <LinkCard
                to="https://myhealthchecked.com/our-tests"
                image={Buy}
                title="Buy tests"
                button_text="Buy tests"
                button_append_class="my-button-class"
              >
                <p className="text-muted">
                  If you don't yet have a test, you can buy one now.
                </p>
              </LinkCard>
            </div>
          ) : (
            <div>
              <p className="text-muted">
                Below is a summary of your current tests.
              </p>
              {testData?.map((test, i) => (
                <div key={i}>
                  <SummaryCard test={test} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Get label for secondary card button, depending on assessment status
function getAssessmentButtonDetails(
  assessment_id,
  can_edit_assessment,
  type,
  testID,
  bid,
  status,
  can_view_test
) {
  if (
    !can_view_test &&
    (status?.toLowerCase() === "error" || status?.toLowerCase() === "rejected")
  ) {
    return {
      label: type?.startsWith("dna-")
        ? "Assessment"
        : assessment_id
          ? "Edit assessment"
          : "Fill assessment",
      link: `#`,
      appendClass: "disabled"
    };
  } else if (
    can_view_test &&
    (status?.toLowerCase() === "error" || status?.toLowerCase() === "rejected")
  ) {
    return {
      label: type?.startsWith("dna-")
        ? "Assessment"
        : assessment_id
          ? "Edit assessment"
          : "Fill assessment",
      link: `#`,
      appendClass: "error"
    };
  }

  // Fixed link for Wellness tests
  if (type?.startsWith("dna-")) {
    return {
      label: "Assessment",
      link: `/assessment/wellness/${testID}`,
      appendClass: ""
    };
  }

  // If no assessment and it can't be edited = no label
  if (!assessment_id && !can_edit_assessment) {
    return { label: "Fill assessment", link: `#`, appendClass: "disabled" };
  }

  // If has read-only assessment, view it - Note: the API uses the testID (test-kit) ID
  if (assessment_id && !can_edit_assessment) {
    return {
      label: "View assessment",
      link:
        type === ""
          ? `/assessment/${testID}/1`
          : `/assessment-details/${testID}`,
      appendClass: ""
    };
  }

  // If has filled assessment and can edit, offer editing - Note: the API uses the testID (test-kit) ID
  if (assessment_id && can_edit_assessment) {
    return {
      label: bid ? "View assessment" : "Edit assessment",
      link: bid ? `/bloods/boots/assessment-details/${type}/${bid}` : `/assessment-details/${testID}`,
      appendClass: ""
    };
  }

  // Note: the API uses the testID (test-kit) ID
  return {
    label: "Fill assessment",
    link: `/assessment/${testID}/1`,
    appendClass: ""
  };
}

const SummaryCard = ({ test }) => {
  const {
    id,
    bid,
    booking_id,
    booking_status,
    detail_link,
    test_type,
    type,
    type_name,
    booking_reference,
    primary_barcode,
    report_id,
    test_kit_status,
    can_view_test,
    status_name,
    status_ts,
    rejected,
    registration_stage
  } = test;
  let image = PCR;
  const isBloodTest =
    type?.startsWith("blood-") || test_type?.startsWith("blood-");

  if (type?.startsWith("dna-")) {
    if (type === "dna-weight") {
      image = iconHelper("Weight");
    } else {
      image = iconHelper(type_name);
    }
  }
  if (isBloodTest)
    image = iconHelper(type_name || getTestNameFromType(test_type));

  const getContinueBookingLink = (stage, bid) => {
    switch (stage) {
      case "type-confirm":
        return `/bloods/boots/${test_type}/booking/consent/${bid}/`;
      case "consent":
        return `/bloods/boots/${test_type}/confirm-details/${bid}/`;
      case "personal-details":
        return `/bloods/boots/assessment/${test_type}/${bid}/1`;
      default:
        break;
    }
  };

  const bookingLink = (stage, bid, bookingStatus, testType) => {

    if (bookingStatus !== 'rejected') {
      return stage === "assessment" ? `/bloods/boots/success-page/${testType}/${bid}`
        : getContinueBookingLink(stage, id);
    }
    else {

      return stage === "assessment" ? `/bloods/boots/success-page/${testType}/${bid}`
        : null
    }

  };
  const {
    label: assessmentLabel,
    link: assessmentLink,
    appendClass: assessmentAppendClass
  } = getAssessmentButtonDetails(
    test.assessment_id,
    test.can_edit_assessment,
    test.type,
    test.id,
    test.bid,
    status_name,
    test.can_view_test
  );

  let link = `/${detail_link}/${id}/`;

  if (test_kit_status === "report-ready" && report_id) {
    link = `/my-reports/${(type || test_type || "")?.startsWith("blood-") ? "blood/" : ""
      }${report_id}`;
  }

  const displayBarcode =
    type?.startsWith("wellness-") || test_type?.startsWith("wellness-")
      ? true
      : false;

  let continueActivateBt = {};
  // If the test was activated but there is no test_ts, we'll show a button to continue the activation
  if (test_kit_status === "activating" && !bid) {
    continueActivateBt = {
      button2_text: "Return to my activation",
      button2_link: `/${detail_link}/resume/${id}/`,
      button2_append_class: assessmentAppendClass
    };
  }

  let customerCareBtn,
    assessmentBtn = {};
  if (test_kit_status === "rejected" || test_kit_status === "error") {
    customerCareBtn = {
      button4_text: "Contact customer care",
      button4_link: "https://myhealthchecked.com/help",
      button4_target: "_blank"
    };
  } else {
    if (!booking_id) {
      assessmentBtn = {
        button3_text: assessmentLabel,
        button3_link: assessmentLink,
        button3_append_class: assessmentAppendClass
      };
    }
  }
  return (
    <LinkCard
      // If item has test_kit_type, redirect to /test-kits/:id, otherwise /tests/:id
      to={booking_id ? bookingLink(registration_stage, id, booking_status, test_type) : link}
      image={image}
      ts={format_date(status_ts)}
      title={
        toSentenceCase(type_name) ||
        toSentenceCase(
          as_title(type) || toSentenceCase(getTestNameFromType(test_type))
        )
      }
      button_text={
        booking_id
          ? (registration_stage === 'assessment' ? "View registration" : "Continue")
          : test_kit_status === "report-ready" && report_id
            ? `View results`
            : `View test`
      }
      button_append_class={
        (test_kit_status === "rejected" || rejected || booking_status === 'rejected') && !can_view_test
          ? `disabled `
          : test_kit_status
      }
      {...continueActivateBt}
      {...assessmentBtn}
      {...customerCareBtn}
    >
      <div>
        <span className="text-muted d-none1 d-md-inline-block1 mr-2">
          {booking_id
            ? "Booking id"
            : !displayBarcode
              ? "Barcode"
              : "Booking reference"}
          :&nbsp;
        </span>
        <code className="inline-code d-inline-block">
          {booking_id
            ? booking_id
            : !displayBarcode
              ? primary_barcode
              : booking_reference}
        </code>
      </div>
      <div>
        <span className="text-muted d-none1 d-md-inline-block1 mr-2">
          {booking_id ? "Booking status" : "Status"}: &nbsp;
        </span>
        <span className="text-dark font-weight-bold d-inline-block">
          {booking_id
            ? toSentenceCase(booking_status)
            : (!rejected && toSentenceCase(status_name)) ||
            (rejected && "Rejected")}
        </span>
      </div>
    </LinkCard>
  );
};
export default TestList;
