import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import DynamicForm from "../../stories/Forms/DynamicForm";
import useApis from "../../services/useAPI";
import { timeAdministeredSchema } from "../../stories/Forms/validationSchema";
import { timeAdministeredFields } from "../../stories/Forms/fields";
import { ErrorDisplay } from "../Errors/ErrorDisplay";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { ArrowRightCircleFill, XSquare } from "react-bootstrap-icons";
import { as_title } from "../../utils";

/**
 * TimeAdministered component
 *
 * This component is responsible for collecting and activating test timestamp information
 * for the test kits. It includes a form to submit the timestamp and displays warnings about
 * exposure to STIs. It handles API calls to update the test timestamp and activate the test.
 *
 * @returns {JSX.Element} The rendered component containing the form and exposure warning modal.
 */

export default function TimeAdministered() {
  const [exposureWarningOpen, setExposureWarningOpen] = useState(false);
  const [hasAcknowledgedWarning, setHasAcknowledgedWarning] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [isShType, setShType] = useState(false);
  const { callApi, resultPost, serverError, resultGet } = useApis();
  const { primaryBarcode } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();

  const typeUrl = `/api/dashboard/check-test/${primaryBarcode}/`;
  const postApiUrl = `/api/dashboard/test-kits/timestamp`;
  const postActivatingUrl = `/api/dashboard/activating/`;

  useEffect(() => {
    callApi({}, typeUrl, "GET")
  }, [primaryBarcode])

  useEffect(() => {
    if (resultGet?.type) {
      setShType(resultGet.type.includes("sex"));
    }
  }, [resultGet]);

  const toggleExposureWarning = () => {
    setExposureWarningOpen(false);
    setHasAcknowledgedWarning(true); // Acknowledge the modal
  };

  useEffect(() => {
    if (resultPost) {
      setShowSpinner(false); 
      if (!serverError) {
        auth?.showToast(
          "Kit activation",
          "Test kit activated successfully!",
          "../assets/mhc.png",
          <ArrowRightCircleFill />
        );

        const activatingPayload = {
          activation_stage: "test-taken",
          code: primaryBarcode,
        };
        callApi(activatingPayload, postActivatingUrl, "POST");

        const redirect = primaryBarcode.startsWith("C")
          ? `/wellness-assessment/${resultPost?.test_kit_id}`
          : `/assessment/${resultPost?.test_kit_id}/1`;
        navigate(redirect);
      }
    }
  }, [resultPost]);

  useEffect(() => {
    if (serverError) {
      setShowSpinner(false)
      auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
    }
    setError(serverError);
  }, [serverError]);

  if (showSpinner || resultPost?.test_kit_id) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="info" />
      </div>
    );
  }

  const handleSubmit = async (formData) => {
    setData(formData);
    if (isShType && !hasAcknowledgedWarning) {
      setExposureWarningOpen(true);
      return;
    }
    setShowSpinner(true); // Start showing the spinner
    const { sample_collection_date, sample_collection_time } = formData;

    // Combines the selected date and time
    const selectedDateTime = new Date(
      `${sample_collection_date}T${sample_collection_time}`
    );

    // Format the date-time with timezone offset
    const year = selectedDateTime.getFullYear();
    const month = String(selectedDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(selectedDateTime.getDate()).padStart(2, "0");
    const hours = String(selectedDateTime.getHours()).padStart(2, "0");
    const minutes = String(selectedDateTime.getMinutes()).padStart(2, "0");
    const seconds = String(selectedDateTime.getSeconds()).padStart(2, "0");

    // Gets the timezone offset in minutes
    const timezoneOffset = new Date().getTimezoneOffset();

    // Converts the timezone offset to hours and minutes
    const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
    const offsetMinutes = Math.abs(timezoneOffset) % 60;

    // Formats the offset as +hh:mm or -hh:mm
    const offsetSign = timezoneOffset > 0 ? "-" : "+";
    const formattedOffset = `${offsetSign}${String(offsetHours).padStart(
      2,
      "0"
    )}:${String(offsetMinutes).padStart(2, "0")}`;

    // Combines the date-time with the dynamic timezone offset (keeping it local)
    const test_ts = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${formattedOffset}`;

    const payload = { test_ts };

    // Calls API with the payload
    callApi(payload, `${postApiUrl}/${primaryBarcode}/`, "POST");

  };

  if (error === "No internet connection. Please check your connection.") {
    return <ErrorDisplay error={error} consent={false} />;
  }

  return (
    <>
      <div className="row justify-content-center time-administered-page">
        <div className="col-md-12">
          <h1 className="mb-4">Activate your test</h1>
          <p className="text-muted">
            Test type:{" "}
            <span className="text-dark font-weight-bold">
              {as_title(resultGet?.type)}
            </span>
          </p>
          <div className="pt-2">
            <DynamicForm
              fields={timeAdministeredFields}
              onSubmit={handleSubmit}
              initialValues={{ ...data }}
              validationSchema={timeAdministeredSchema}
            />
          </div>
        </div>
      </div>
      <Modal show={exposureWarningOpen} onHide={toggleExposureWarning}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            When testing for sexually transmitted infections (STIs), some
            infections do not show up immediately after sex. The testing window,
            or the period of time after exposure you will get an accurate
            result, can vary by infection type. For the most accurate test
            results, we recommend that you follow the accurate testing windows
            listed below.
          </p>
          <p>
            <strong>Chlamydia</strong> - 2 weeks after exposure
          </p>
          <p>
            <strong>Gonorrhoea</strong> - 2 weeks after exposure
          </p>
          <p>
            <strong>HIV</strong> - 45 days after exposure
          </p>
          <p>
            <strong>Syphilis</strong> - 12 weeks after exposure
          </p>
          <p>
            If you've had high-risk exposure to either syphilis or HIV, we
            recommend repeating the test again in 12 weeks.
          </p>
          <p>
            If you are unsure about whether you are within a testing window, you
            should do a test now and another test at a later date.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={toggleExposureWarning}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}